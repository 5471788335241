import React, {Component} from "react";
import TopNav from "./TopNav";
import Header from "./Header";
import Cars from "./components/Cars";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import Footer from "./components/Footer";


class CarsListing extends Component{
    state = {
        cars:[]
    }
    async componentDidMount(){
        const response = axios.get(buildAPIUrl('get-all-cars')).then((res)=>{
            this.setState({
                cars:res.data
            })
        })
    }
    render() {
        return (
            <>
                <TopNav />
                <Header />

                <Cars cars={this.state.cars} />
                <Footer />
            </>
        )
    }
}
export default CarsListing