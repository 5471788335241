import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import TopNavNew from "./TopNavNew";
import Header from "./Header";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import swal from "sweetalert";

class CustomersList extends Component{
    state={
        participants:"",
        success:false,
    }
    componentDidMount() {
        const response = axios.get(buildAPIUrl('get_users')).then((res)=>{
            this.setState({
                participants: res.data,
            })

        }).catch((error)=>{

        })
    }
    handleDelete = (user)=>{
        const response = axios.post(buildAPIUrl(`delete_user/${user}`)).then(()=>{
            swal({
                title: "Successful!",
                text: "User deleted successfully",
                icon: "success",
                button: "Close",
            }).then(()=>{
                window.location.reload()
            })
        }).catch((error)=>{

        })

    }

    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="container px-4 mt-5 auctions">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h6>Users</h6>
                                </div>
                                <div className="card-body p-0 m-0">
                                    {
                                        this.state.loading ? <>
                                                <div className="container-fluid d-flex justify-content-center">
                                                    <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                                </div>
                                            </> :
                                            <table className="table table-nowrap">
                                                <tr className="">
                                                    <th>Name</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                    <th>Delete</th>
                                                </tr>
                                                {
                                                    this.state.participants ?
                                                        this.state.participants.map((participant) => (

                                                            <tr>
                                                                <td>{participant.first_name} {participant.last_name}</td>
                                                                <td>{participant.phone}</td>
                                                                <td>{participant.email}</td>
                                                                <td><a style={{cursor:"pointer"}} onClick={()=>this.handleDelete(participant.id)} className="text-danger">Delete</a></td>

                                                            </tr>
                                                        ))
                                                        : <>
                                                            <h6>No data found</h6>
                                                        </>
                                                }

                                            </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default CustomersList