import React, {Component, Suspense} from "react";
import Carimg from "../../../assets/car1.jpg"
import {Link} from "react-router-dom";
import Footer from "./Footer";
import {FaMapMarkerAlt, FaRulerCombined} from "react-icons/fa";
import {MdCarRental, MdHouse} from "react-icons/md";

class Salvages extends Component{

    render() {

        return (
            <div className="container-fluid pt-5 pb-5 cars-component bg-light px-0 ">

                <div className="container">
                    {
                        this.props.type==="current" ?
                            <h3 style={{ fontWeight:"600", fontSize:"40px"}}>Current <span style={{color:"orangered"}}> Auction</span></h3>
                            :
                            <>
                                {
                                    this.props.type==="future" ?
                                         <>
                                             <h3 style={{ fontWeight:"600", fontSize:"40px"}}>Future Auction <span style={{color:"orangered"}}> Salvages</span></h3>
                                         </> :
                                        <>
                                            <h3 style={{ fontWeight:"600", fontSize:"40px"}}>Featured <span style={{color:"orangered"}}> Salvages</span></h3>
                                        </>
                                }
                            </>

                    }


                </div>
                <div className="container pb-5">

                    <div className="row mt-3">
                        {
                            this.props.salvages.length > 0 ?

                                this.props.salvages.map((car)=>(

                                    <div className="col-md-4 mt-5">
                                        <Link to={this.props.type==="current" ? `/place-bid/${car.id}`: `/car-details/${car.id}`} style={{textDecoration:"none"}}>
                                            <div className="card bg-white">
                                                <div className="car-img"  >
                                                    {
                                                        car.images.length > 0 ? <>
                                                            {
                                                                car.images.slice(0,1).map((image)=>(
                                                                    <img src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")} style={{width:"100%", height:"200px"}} />
                                                                ))
                                                            }
                                                        </> : <></>
                                                    }

                                                </div>
                                                <div className="car-details px-3 pt-3">
                                                    <h5>{car.regNo}</h5>
                                                    <div className={"row p-0"}>
                                                        <div className={"col-md-4"}>
                                                            <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}> <FaMapMarkerAlt /> {car.location} </h5>
                                                        </div>
                                                        <div className={"col-md-4"}>
                                                            <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><MdCarRental /> {car.make}</h5>
                                                        </div>
                                                        <div className={"col-md-4"}>

                                                            <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><MdCarRental /> {car.model}</h5>
                                                        </div>
                                                    </div>
                                                    <hr style={{height:"1px", border:"none", backgroundColor:"gray"}} />
                                                    <div className={"row mb-4"}>
                                                        <div className={"col-md-6 "}>
                                                            <h6>KSh. {(+car.reservePrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>
                                                        </div>
                                                        <div className={"col-md-6  "}>
                                                            <div className={"container-fluid d-flex align-items-center justify-content-center "}>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Link>
                                    </div>
                                ))
                                : <>
                                    <p>There are no salvages on sale or in auction</p>
                                </>
                        }

                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}
export default Salvages