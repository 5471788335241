import React, {Component, Suspense} from "react";
import TopNav1 from "../../Landing/TopNav";
import Header1 from "../../Landing/Header";
import TopNav from "./TopNavNew";
import Header from "./Header";
import Countdown from "react-countdown";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import Carousel from "react-bootstrap/Carousel";
import ReactWhatsapp from "react-whatsapp";
import {FaWhatsapp} from "react-icons/fa";

class PropertyBids extends Component{
    state = {
        property_id: this.props.match.params.id,
        auction_id: localStorage.getItem('auction_id'),
        property: "",
        images:[],
        bidAmount:"",
        highestBid:"",
        bids:[],
        loading: true,
        success:false,
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async componentDidMount(){

        const response = await axios.get(buildAPIUrl(`property-details/${this.state.property_id}`)).then((res)=>{
            console.log(res)
            this.setState({
                property: res.data,
                images:res.data.propertyimages,
                loading:false
            })
        })

        setInterval(async ()=>{
            const response = await axios.get(buildAPIUrl(`get-property-bids/${this.state.property_id}/${localStorage.getItem('auction_id')}`)).then((res)=>{
                console.log("highest", res)
                this.setState({
                    salvage: res.data.salvage,
                    images:res.data.salvage.propertyimages,
                    highestBid: res.data.highestbid,
                    bids: res.data.salvage.propertybids,
                    auctionCategory:res.data.auction.auctionCategory,
                    auctionEndTime:res.data.auction.auctionEndTime,
                    auction:localStorage.getItem('item'),
                    loading:false
                })
            })
            const checktime = await axios.get(buildAPIUrl(`current-auction`)).then((res)=>{
                console.log("Current-time", res)
                this.setState({
                    auctionDate:res.data
                })
            }).catch((error)=>{
                if(error.response.data.message==="There are no active auctions at the moment. Please check again later"){
                    //Redirect user to the end of auction page
                    window.location.assign('/auction-ended')
                }
            })
        },3000)

    }
    handleAddBids = async (e) => {
        e.preventDefault()
        if(new Date().getTime() > new Date(this.state.auctionendtime).getTime()) {
            this.setState({
                timeup:true
            })
            setTimeout(()=>{
                localStorage.clear()
                window.location.assign("/login")
            }, 3000)
        } else {
            if (this.state.bidAmount > this.state.highestBid || !this.state.bidAmount === this.state.highestBid) {
                document.getElementById('submit-button').innerHTML = 'Submitting...'
                const res = await axios.post(buildAPIUrl('add-property-bids'), {
                    property_id: this.state.property_id,
                    auction_id: this.state.auction_id,
                    bidAmount: this.state.bidAmount,
                    bonustimestatus:this.state.bonustime,
                    auctionendtime:this.state.auctionendtime,
                    name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                    email: localStorage.getItem('email'),
                    user_id:localStorage.getItem('item')
                }).then((response) => {
                    this.setState({
                        bidAmount: "",
                        success: true
                    })
                    setInterval(() => {
                        this.setState({
                            success: false
                        })
                    }, 1000)
                    document.getElementById('submit-button').innerHTML = 'Submit'
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                this.setState({
                    lowbid: true
                })
                setTimeout(() => {
                    this.setState({
                        lowbid: false
                    })
                }, 3000)
            }
        }
    }
    render() {
        return (
            <>
                <TopNav />
                <Header />
                {
                    this.state.loading ? <>
                            <div className="container-fluid d-flex justify-content-center">
                                <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                            </div>
                        </> :
                        <div className="container-fluid mt-5 item-details">
                            <div className="container">
                                <div className="row top-details">
                                    <div className="col-md-4">
                                        <h3>{this.state.property.propertyName}</h3>
                                    </div>
                                    <div className="col-md-8 d-flex justify-content-end">
                                        {
                                            this.state.highestBid === null ?
                                                <h6 className="text-center">There are no bids yet</h6> :
                                                <h4 className="text-center text-success">Highest
                                                    Bid: {this.state.highestBid} </h4>

                                        }
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6 details-img">
                                        <div className="container-fluid">
                                            <Carousel>
                                                {
                                                    this.state.images.map((image, key) => (
                                                        <Carousel.Item>
                                                            <img
                                                                className="d-block w-100"
                                                                src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}
                                                                alt="First slide"
                                                            />

                                                        </Carousel.Item>
                                                        /* <div className={key === 0 ? 'active carousel-item' : 'carousel-item' }>
                                                             <img className="d-block w-100" src= alt="First slide" />
                                                         </div>*/
                                                    ))
                                                }

                                            </Carousel>
                                        </div>
                                        <div className="container-fluid mt-5 ">
                                            <div className="card-header">
                                                <h6>Overview</h6>
                                            </div>
                                            <table
                                                className="table table-nowrap table-centered align-middle m-0">
                                                <tr className="options-table-header">
                                                    <th>Item</th>
                                                    <th>Details</th>

                                                </tr>
                                                <tr>
                                                    <td>Property Name</td>
                                                    <td>{this.state.property.propertyName}</td>

                                                </tr>
                                                <tr>
                                                    <td>Size</td>
                                                    <td>{this.state.property.size}</td>

                                                </tr>
                                                <tr>
                                                    <td>Location</td>
                                                    <td>{this.state.property.location}</td>

                                                </tr>
                                                <tr>
                                                    <td>Property Type</td>
                                                    <td>{this.state.property.nature}</td>

                                                </tr>
                                                <tr>
                                                    <td>Price</td>
                                                    <td>{(+this.state.property.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>

                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5 m-0 p-0">
                                        <div className="card bg-white pt-4">
                                            <div className="card-header">
                                                <div>
                                                    <div className={this.state.timeup ? "alert alert-danger" : "d-none"}>
                                                        Time is up. Logging you out...
                                                    </div>
                                                    <div className={this.state.lowbid ? "alert alert-danger" : "d-none"}>
                                                        Please increase your bid. It cannot be lower than or equal to {this.state.highestBid}
                                                    </div>
                                                    <div><h6 className="">Place Bid</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <form onSubmit={this.handleAddBids}>
                                                <h6 className={this.state.success ? "alert alert-success" : "d-none"}
                                                    style={{fontSize: "12px", fontWeight: "400"}}>Bid Placed
                                                    successfully</h6>
                                                <div className="mb-3 mt-2">
                                                    <input type="text" name="bidAmount" className="form-control"
                                                           onChange={this.handleInput} value={this.state.bidAmount}
                                                           placeholder="Amount"/>
                                                </div>
                                                <div className="mb-3">
                                                    {
                                                        localStorage.getItem('uniq') === null ?
                                                            <button onClick={this.handleLogin}
                                                                    className="btn btn-outline-dark">Login to start
                                                                bidding</button> :
                                                            <>
                                                                {
                                                                    this.state.auctionDate === "None" ?
                                                                        <p>Auction has ended</p> :
                                                                        <button id="submit-button"
                                                                                className="btn btn-outline-dark">Submit
                                                                            Bid</button>
                                                                }

                                                            </>

                                                    }
                                                </div>
                                            </form>
                                        </div>
                                        <div className="card mt-2">
                                            <div className="card-header">
                                                <h6>Other Bids</h6>
                                            </div>
                                            <div className="card-body p-0 m-0">
                                                <table className="table table-nowrap table-centered align-middle">
                                                    <tr className="options-table-header">
                                                        <th>Property Name</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                    {
                                                        this.state.bids === null ? <p>No bids Yet</p> : <>
                                                            {
                                                                this.state.bids.filter(result => result.auction_id.toString() === localStorage.getItem('auction_id').toString()).map((bid) => (
                                                                    <tr>
                                                                        <td>{this.state.salvage.propertyName}</td>
                                                                        <td>{bid.bidAmount}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </>
                                                    }
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </>
        );
    }
}
export default PropertyBids