import React, {Component} from "react";
import TopNav from "../components/TopNavNew";
import Header from "../components/Header";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";

class CurrentAuction extends Component{
    state = {
        active:"",
        salvages:[],
        bids:"",
        loading:true,
        auction:""
    }
    componentDidMount(){
        const response1 = axios.get(buildAPIUrl('current-auction')).then((res1)=>{
            console.log(res1)
            this.setState({
                salvages: res1.data.salvages,
                bids: res1.data.salvages.bids,

            })
        })
        setInterval(()=>{
            const  response = axios.get(buildAPIUrl('get-active-users')).then((res)=>{
                this.setState({
                    active: res.data
                })
            });
            const response1 = axios.get(buildAPIUrl('current-auction')).then((res1)=>{
                console.log(res1)
                this.setState({
                    salvages: res1.data.salvages,
                    bids: res1.data.salvages.bids,
                    auction:res1.data.auction_id

                })
            })
        }, 5000)
        setInterval(()=>{
            this.setState({
                loading:false
            })
        }, 3000)
    }
    render() {
        return (
            <>
                <TopNav />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card p-0 m-0">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-8"> <h6>Received Payments</h6></div>
                                        <div className="col-md-4"><span className="text-primary" style={{fontSize:"12px"}}>View Payments</span> </div>
                                    </div>

                                </div>
                                <div className="card-body p-0 m-0">
                                    <table className="table">
                                        <tr>
                                            <th>Name</th>
                                            <th>Amount</th>
                                            <th>Reg No.</th>
                                        </tr>
                                        <tr>
                                            <td>Daniel</td>
                                            <td>1,000</td>
                                            <td>KCA 1234</td>
                                        </tr>
                                        <tr>
                                            <td>Mwangi</td>
                                            <td>1,000</td>
                                            <td>KCA 1234</td>
                                        </tr>
                                        <tr>
                                            <td>Walter</td>
                                            <td>1,000</td>
                                            <td>KCA 1234</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="mt-3">Current Auction</h6>
                                </div>
                                <div className="card-body p-0 m-0">
                                    {
                                        this.state.loading ? <>
                                                <div className="container-fluid d-flex justify-content-center">
                                                    <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                                </div>
                                            </> :
                                            <table className="table table-hover table-striped table-borderless ">
                                                <tr className="options-table-header">
                                                    <th>Reg Number</th>
                                                    <th>Make</th>
                                                    <th>Model</th>
                                                    <th style={{width: "50%"}}>Bids</th>
                                                </tr>
                                                {
                                                    this.state.salvages ? <>
                                                        {
                                                            this.state.salvages.map((salavge) => (
                                                                <tr>
                                                                    <td>{salavge.regNo}</td>
                                                                    <td>{salavge.make}</td>
                                                                    <td>{salavge.model}</td>
                                                                    <td>{
                                                                        salavge.bids ? <ol>
                                                                            {
                                                                                salavge.bids.filter(result => result.auction_id==this.state.auction.toLocaleString()).map((bid) => (
                                                                                    <li className="bid-list"> <span> {bid.name} </span> <span
                                                                                        className="text-black" style={{
                                                                                        fontWeight: "700",
                                                                                        color: "green"
                                                                                    }}> {bid.bidAmount} </span>
                                                                                        <i style={{fontSize: "12px"}}>{new Date(bid.created_at).toLocaleDateString()} {new Date(bid.created_at).toLocaleTimeString()}</i>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </ol> : <p>There are no bids</p>
                                                                    }</td>

                                                                </tr>
                                                            ))
                                                        }

                                                    </> : <p>There is no active auction running</p>
                                                }
                                            </table>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default CurrentAuction