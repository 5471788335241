import React, {Component} from "react";
import { HiPhone, HiOutlineLocationMarker, HiOutlineClock} from "react-icons/hi";
import {FaFacebook, FaPhoneAlt, FaRegBell, FaRegEnvelope, FaRegUserCircle} from "react-icons/fa"
import Logo from "../../assets/reizenwhite.png";
import {Link} from "react-router-dom";
import MediaQuery from "react-responsive";
class TopNav extends Component{
    render() {
        return(
            <>
                <MediaQuery minWidth={1200}>
                    <div className="container-fluid pt-2 pb-2 top-nav">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-12">
                                    <Link to={"/"}><img className="logo" src={Logo}/></Link>
                                </div>
                                <div className="col-md-4 mt-2 col-12 d-flex justify-content-center align-items-center">
                                    <span className="d-flex align-items-center"><FaRegEnvelope style={{paddingRight:"2%"}}  /> info@reizen.auction</span>
                                    <span className="d-flex align-items-center"><FaPhoneAlt style={{paddingRight:"2%"}} /> 0791 230249</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>

                <MediaQuery maxWidth={460}>
                    <div className="container-fluid pt-2 pb-2 top-nav">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-12 d-flex justify-content-center pt-3">
                                    <Link to={"/"}><img className="logo" src={Logo}/></Link>
                                </div>
                                <div className="col-md-4 mt-2 col-12 d-flex justify-content-around align-items-center p-0">
                                    <span className="d-flex align-items-center"><FaRegEnvelope style={{paddingRight:"2%"}}  /> info@reizen.auction</span>
                                    <span className="d-flex align-items-center"><FaPhoneAlt style={{paddingRight:"2%"}} /> 0791 230249</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className="container-fluid pt-2 pb-2 top-nav">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-12">
                                    <Link to={"/"}><img className="logo" src={Logo}/></Link>
                                </div>
                                <div className="col-md-4 mt-2 col-12 d-flex justify-content-center align-items-center">
                                    <span className="d-flex align-items-center"><FaRegEnvelope style={{paddingRight:"2%"}}  /> info@reizen.auction</span>
                                    <span className="d-flex align-items-center"><FaPhoneAlt style={{paddingRight:"2%"}} /> 0791 230249</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>

            </>

        )
    }
}

export default TopNav