import React, {Component} from "react";
import TopNav from "../components/TopNavNew";
import Header from "../components/Header";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {Link} from "react-router-dom";
import {FaRegEdit} from 'react-icons/fa'

class AddSalvages extends Component{
    state = {
        companies:"",
        make:"",
        model:"",
        reserve:"",
        companyReserve:"",
        company_id:"",
        category:"",
        regNo:"",
        location:"",
        salvages:"",
        success:false,
        delete:false
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async componentDidMount() {
        const response = await axios.get(buildAPIUrl('getcompanies')).then((res)=>{
              this.setState({
                companies: res.data
            })
        }).catch((err)=>{

        })
        const respo = await axios.get(buildAPIUrl('get-salvages')).then((resp)=>{
            console.log(resp)
            this.setState({
                salvages: resp.data
            })
        }).catch((err)=>{

        })
    }
    handleSalvageSave = async (e) => {
        e.preventDefault()
        const resp = await axios.post(buildAPIUrl('add-salvages'), {
            regNo: this.state.regNo,
            make: this.state.make,
            model: this.state.model,
            reserve: this.state.reserve,
            companyreserve: this.state.companyReserve,
            company_id: this.state.company_id,
            location: this.state.location,
            category: this.state.category
        }).then((res)=>{
            this.setState({
                regNo: "",
                make: "",
                model: "",
                reserve: "",
                companyreserve: "",
                company_id: "",
                location: "",
                category: "",
                success: true
            })
            setTimeout(() => {
                this.setState({
                    success:false
                })
            }, 3000);
        }).catch((err)=>{

        })
    }
    handleDeleteSalvage = (id) => {
        const deletesalvage = axios.post(buildAPIUrl(`delete-salvage/${id}`)).then((res)=>{
            this.setState({
                delete:true
            })
            setTimeout(()=>{
                this.setState({
                    delete:false
                })
                window.location.reload()
            }, 3000)
        }).catch((error)=>{

        })
    }
    render() {
        return (
            <>
                <TopNav />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card ">
                                <div className="card-header">
                                    <h6 className="mt-3">Add Salvages</h6>
                                    <br/>

                                </div>
                                <div className="card-body">
                                    <div className={this.state.success ? "alert alert-success" : "d-none"}>Salvage Added Successfully</div>
                                    <form onSubmit={this.handleSalvageSave}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label>Registration Number</label>
                                                    <input className="form-control" name="regNo" onChange={this.handleInput} value={this.state.regNo}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label>Make</label>
                                                    <input className="form-control" name="make" onChange={this.handleInput} value={this.state.make}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label>Model</label>
                                                    <input className="form-control" name="model" onChange={this.handleInput} value={this.state.model}/>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label>Reserve Price </label>
                                                    <input className="form-control" name="reserve" onChange={this.handleInput} value={this.state.reserve} />
                                                </div>
                                                <div className="mb-3">
                                                    <label>Reizen Reserve </label>
                                                    <input className="form-control" name="companyReserve" onChange={this.handleInput} value={this.state.companyReserve} />
                                                </div>
                                                <div className="mb-3">
                                                    <label>Location</label>
                                                    <input className="form-control" name="location" onChange={this.handleInput} value={this.state.location}/>
                                                </div>
                                            </div>
                                            <div className="col-md-4">

                                                <div className="mb-3">
                                                    <label>Owner</label>
                                                    <select className="form-control" name="company_id" onChange={this.handleInput} value={this.state.company_id}>
                                                        <option>---Select Company---</option>
                                                        {
                                                            this.state.companies ?

                                                                this.state.companies.map((company)=>(
                                                                    <option value={company.id}>{company.first_name + " " + company.last_name}</option>
                                                                ))

                                                                : <option disabled>No Companies Loaded</option>
                                                        }
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label>Category</label>
                                                    <select className="form-control" name="category" onChange={this.handleInput} value={this.state.category}>
                                                        <option>---Select  Type---</option>
                                                        <option value="1">Auction</option>
                                                        <option value="2">Listing</option>
                                                        <option value="3">Sold</option>
                                                        <option value="4">Car for Sale</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 d-flex justify-content-end">
                                                    <button className="btn btn-save">Save</button>
                                                </div>
                                            </div>
                                        </div>


                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-12 mt-5">
                            <div className="card">
                                <div className={this.state.delete ? "m-2 alert alert-success": "d-none"}>
                                    Deleting salvage...
                                </div>
                                <div className="card-header">
                                    <h6 className="mt-3">Salvages</h6>
                                </div>
                                <div className="card-body p-0 m-0">
                                    <table className="table table-hover table-striped table-borderless ">
                                        <tr className="options-table-header">
                                            <th>Reg Number</th>
                                            <th>Make</th>
                                            <th>Model</th>
                                            <th>Location</th>
                                            <th>Reserve</th>
                                            <th>Add</th>
                                            <th>Options</th>
                                        </tr>
                                        {
                                            this.state.salvages ?
                                                this.state.salvages.map((salvage)=>(
                                                    <tr>
                                                        <td>{salvage.regNo}</td>
                                                        <td>{salvage.make}</td>
                                                        <td>{salvage.model}</td>
                                                        <td>{salvage.location}</td>
                                                        <td>{salvage.reservePrice.toLocaleString()}</td>
                                                        <td>
                                                            <Link to={`upload-images/${salvage.id}`}>Add Images</Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`edit-salvage/${salvage.id}`} > <FaRegEdit /> Edit</Link>
                                                            <br/>
                                                            <Link className={"text-danger mt-2 mb-2"} onClick={()=>this.handleDeleteSalvage(salvage.id)} > <FaRegEdit /> Delete</Link>

                                                        </td>
                                                    </tr>
                                                ))

                                                : <p>No salvages found</p>
                                        }

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default AddSalvages