import React, {Component} from "react";
import Carimg from "../../../assets/car1.jpg";

class Auctions extends Component{
    render() {
        return (
            <div className="container mt-5 mb-5 auctions">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card pb-5 ">
                            <div className="card-header">
                                <h3 className="section-title">Current Auction</h3>
                            </div>
                            <div className="card-body p-0 m-0">
                                <div className="container-fluid cars-component">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <div className="card p-2">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="car-img">
                                                                <img src={Carimg}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="car-details mt-3">
                                                                <h6>Ksh. 300,000</h6>
                                                                <h6>Toyota, Rush</h6>
                                                                <h6 className="views">Bids (300) </h6>
                                                            </div>
                                                            <button className="btn btn-outline-dark mt-4 mb-2">Place Bid</button>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="card p-2">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="car-img">
                                                                <img src={Carimg}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="car-details mt-3">
                                                                <h6>Ksh. 300,000</h6>
                                                                <h6>Toyota, Rush</h6>
                                                                <h6 className="views">Bids (300) </h6>
                                                            </div>
                                                            <button className="btn btn-outline-dark mt-4 mb-2">Place Bid</button>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="card p-2">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="car-img">
                                                                <img src={Carimg}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="car-details mt-3">
                                                                <h6>Ksh. 300,000</h6>
                                                                <h6>Toyota, Rush</h6>
                                                                <h6 className="views">Bids (300) </h6>
                                                            </div>
                                                            <button className="btn btn-outline-dark mt-4 mb-2">Place Bid</button>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="card p-2">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="car-img">
                                                                <img src={Carimg}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="car-details mt-3">
                                                                <h6>Ksh. 300,000</h6>
                                                                <h6>Toyota, Rush</h6>
                                                                <h6 className="views">Bids (300) </h6>
                                                            </div>
                                                            <button className="btn btn-outline-dark mt-4 mb-2">Place Bid</button>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Auctions