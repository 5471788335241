import './App.css';
import React, {lazy, Suspense} from "react";
import Home from "./components/Landing/Home";
import { useIdleTimer } from 'react-idle-timer'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import SalvageDetails from "./components/Landing/components/SalvageDetails";
import Contact from "./components/Landing/Contact";
import Dashboard from "./components/Admin/Dashboard";
import Dashboard1 from "./components/Company/Dashboard";
import Dashboard2 from "./components/Customer/Dashboard";
import AddSalvages from "./components/Admin/Salvages/AddSalvages";
import Future from "./components/Admin/Salvages/Future";
import CurrentAuction from "./components/Admin/Salvages/CurrentAuction";
import CurrentAuction1 from "./components/Company/Salvages/CurrentAuction";
import Past from "./components/Admin/Salvages/Past";
import Salvages from "./components/Admin/Salvages/Salvages";

import Register from "./components/Landing/Authentication/Register";
import Login from "./components/Landing/Authentication/Login";
import Forgot from "./components/Landing/Authentication/Forgot";
import AddCompany from "./components/Admin/components/AddCompany";
import AddParticipant from "./components/Admin/components/AddParticipant";
import UploadImages from "./components/Admin/components/UploadImages";
import AddSalvageToAuction from "./components/Admin/Salvages/AddSalvageToAuction";
import EditSalvage from "./components/Admin/Salvages/EditSalvage";
import Bids2 from "./components/Customer/components/Bids2";
import Payment from "./components/Landing/Payment";
import AuctionDetails from "./components/Admin/components/AuctionDetails";
import AddBids from "./components/Admin/components/AddBids";
import CompanyAuctionDetails from "./components/Company/components/CompanyAuctionDetails";
import AddProperty from "./components/Admin/Properties/AddProperty";
import UploadPropertyImages from "./components/Admin/Properties/UploadPropertyImages";
import PropertyDetails from "./components/Landing/components/PropertyDetails";
import Property from "./components/Landing/components/Property";
import PropertyListings from "./components/Landing/PropertyListings";
import EditAuction from "./components/Admin/EditAuction";
import Cars from "./components/Landing/components/Cars";
import CarsListing from "./components/Landing/CarsListing";
import EditProperty from "./components/Admin/Properties/EditProperty";
import ResetPassword from "./components/Landing/Authentication/ResetPassword";
import UserCurrentAuction from "./components/Landing/UserCurrentAuction";
import UserFutureAuction from "./components/Landing/UserFutureAuction";
import AddDepositParticipant from "./components/Admin/components/AddDepositParticipant";
import CustomersList from "./components/Admin/components/CustomersList";
import AddPropertyToAuction from "./components/Admin/Properties/AddPropertyToAuction";
import PropertyFutureAuction from "./components/Landing/PropertyFutureAuction";
import AuctionEnded from "./components/Customer/components/AuctionEnded";
import PropertyBids from "./components/Customer/components/PropertyBids";
import UploadItem from "./components/Customer/components/UploadItem";
const CurrentAuctionFront = lazy(()=>import("./components/Landing/CurrentAuctionFront"))
const Bids = lazy(()=>import("./components/Customer/components/Bids"))
const FutureAuctions = lazy(()=>import("./components/Landing/FutureAuctions"))
const FutureAuctionList = lazy(()=>import("./components/Landing/FutureAuctionList"))
const CarDetails = lazy(()=>import("./components/Landing/components/CarDetails"))
const SalvageListings = lazy(()=>import("./components/Landing/SalvageListings"))


function App() {
    const onPrompt = () => {
        // Fire a Modal Prompt
    }

    const onIdle = () => {
        localStorage.clear()
        window.location.assign('/login')
    }

    const onActive = (event) => {
        // Close Modal Prompt
        // Do some active action
    }

    const onAction = (event) => {
        // Do something when a user triggers a watched event
    }

    const {
        start,
        reset,
        activate,
        pause,
        resume,
        isIdle,
        isPrompted,
        isLeader,
        getTabId,
        getRemainingTime,
        getElapsedTime,
        getLastIdleTime,
        getLastActiveTime,
        getTotalIdleTime,
        getTotalActiveTime
    } = useIdleTimer({
        onPrompt,
        onIdle,
        onActive,
        onAction,
        timeout: 1000 * 60 * 200,
        promptTimeout: 0,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        name: 'idle-timer',
        syncTimers: 0,
        leaderElection: false
    })
  return (
    <div className="App">
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/salvage-details" component={SalvageDetails} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/future-auctions" component={FutureAuctions} />
                <Route exact path="/current-auction" component={CurrentAuctionFront} />
                /*------Admin Routes ------*/
                <Route exact path="/admin-dashboard" component={Dashboard} />
                <Route exact path="/admin-add-salvages" component={AddSalvages} />
                <Route exact path="/admin-future-auction" component={Future} />
                <Route exact path="/admin-current-auction" component={CurrentAuction} />
                <Route exact path="/admin-past-auction" component={Past} />
                <Route exact path="/admin-salvages" component={Salvages} />
                <Route exact path="/addcompany" component={AddCompany} />
                <Route exact path="/add-participant" component={AddParticipant} />
                <Route exact path="/upload-images/:id" component={UploadImages} />
                <Route exact path="/add-salvage-to-auction/:id" component={AddSalvageToAuction} />
                <Route exact path="/add-property-to-auction/:id" component={AddPropertyToAuction} />
                <Route exact path="/edit-salvage/:id" component={EditSalvage} />
                <Route exact path="/auction-details/:id" component={AuctionDetails} />
                <Route exact path="/add-bids" component={AddBids} />
                <Route exact path="/add-property" component={AddProperty} />
                <Route exact path="/upload-property-images/:id" component={UploadPropertyImages} />
                <Route exact path="/edit-auction/:id" component={EditAuction} />
                <Route exact path="/edit-property/:id" component={EditProperty} />
                <Route exact path="/deposit-participant/:id" component={AddDepositParticipant} />
                <Route exact path="/customers-list" component={CustomersList} />
                /*------Company Routes ------*/
                <Route exact path="/company-dashboard" component={Dashboard1} />
                <Route exact path="/company-currentauction" component={CurrentAuction1} />
                <Route exact path="/company-auction-details/:id" component={CompanyAuctionDetails} />

                /*------User Routes ------*/
                <Route exact path="/user-dashboard" component={Dashboard2} />
                <Route exact={true} path={"/property-place-bid/:id"} component={PropertyBids} />
             {/*   <Route exact path="/place-bid1" component={Bids2} />*/}
                <Route exact path="/place-bid/:id" component={Bids2} />
                <Route exact path="/payment" component={Payment} />
                <Route exact path="/future-auction-list/:id" component={FutureAuctionList} />
                <Route exact path="/future-auction-property-list/:id" component={PropertyFutureAuction} />
                <Route exact path="/salvage-details/:id" component={SalvageDetails} />
                <Route exact path="/property-details/:id" component={PropertyDetails} />
                <Route exact path="/car-details/:id" component={CarDetails} />
                <Route exact path="/properties" component={PropertyListings} />
                <Route exact path="/cars" component={CarsListing} />
                <Route exact path="/user-current-auction" component={UserCurrentAuction} />
                <Route exact path="/user-future-auction" component={UserFutureAuction} />
                <Route exact path="/salvage-listings" component={SalvageListings} />
                <Route exact path={"/auction-ended"} component={AuctionEnded} />
                <Route exact={true} path={"/upload-item"} component={UploadItem} />
                /*-----Authentication------*/
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgot" component={Forgot} />
                <Route exact path="/reset-password" component={ResetPassword} />
            </Switch>
            </Suspense>
        </Router>
    </div>
  );
}

export default App;
