import React, {Component} from "react";
import TopNav from "./TopNav";
import Header from "./Header";

class Payment extends Component{
    render() {
        return (
            <>
                <TopNav />
                <Header />
                <div className="container-fluid mt-5">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Payment</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tr>
                                            <td><h6 className="text-dark font-weight-bold"> > Open Mpesa
                                                Application</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="text-dark font-weight-bold"> > Select Lipa na Mpesa</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="text-dark font-weight-bold"> > Select Paybill and Enter:
                                                295210</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="text-dark font-weight-bold"> > Select Account Number and
                                                Enter: </h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="text-dark font-weight-bold"> > Enter Amount: KES
                                                1000</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="text-dark font-weight-bold"> > Enter Your Pin</h6></td>
                                        </tr>
                                        <tr>
                                           <td><button className="btn btn-save mt-2">Click here to confirm payment</button></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default Payment