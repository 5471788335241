import React, {Component} from "react";
import TopNav from "./TopNav";
import Header from "./Header";
import Property from "./components/Property";
import Properties from "./components/Properties";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import Footer from "./components/Footer";

class PropertyListings extends Component{
    state = {
        properties:[]
    }
    async componentDidMount() {
        let auctionid = this.props.match.params.id
        /* const response = await axios.get(buildAPIUrl(`landing-future-list`)).then((res)=>{
             this.setState({
                 salvages: res.data.salvages
             })
         })*/
        const response1 = await axios.get(buildAPIUrl(`get-all-properties`)).then((res1)=>{
            this.setState({
                properties: res1.data
            })
        })
    }
    render() {
        return (
            <>
                <TopNav />
                <Header />
                <Properties properties={this.state.properties} />
                <Footer />
            </>
        )
    }
}
export default PropertyListings