import React, {Component} from "react";
import Propimg from "../../../assets/prop.jpg"
import {Link} from "react-router-dom";

class Property extends Component{
    render() {
        return (
            <div className="container-fluid cars-component" style={{padding:"2rem 0"}}>
                <div className="container">
                    <div className="row">
                        {
                            this.props.properties ?

                                this.props.properties.map((property)=>(
                                    <div className="col-md-6 mt-3">
                                        <div className="card p-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="car-img">
                                                        {
                                                            property.propertyimages.slice(0,1).map((image)=>(
                                                                <img src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")} style={{height:"200px"}}/>
                                                            ))
                                                        }

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="car-details mt-3">
                                                        <h6>{property.propertyName}</h6>
                                                        <h6 style={{fontWeight:"400"}}>{(+property.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>
                                                        <h6 className="views"> {property.propertyName}</h6>
                                                        <h6 className="views"> {property.location}</h6>
                                                        <h6 className="views"> {property.nature}</h6>
                                                    </div>

                                                    <Link to={`/property-details/${property.id}`} className="btn btn-outline-dark mt-4 mb-2">View Property</Link>




                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                ))
                                : <>
                                    <p>Currently we do not have any properties on sale</p>
                                </>
                        }

                    </div>
                </div>
            </div>
        )
    }
}
export default Property