import React, {Component} from "react";
import Logo from "../../../assets/reizen.png"
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import swal from "sweetalert"
import TopNav from "../TopNav";

class Register extends Component{
    state = {
        firstName:"",
        lastName:"",
        email:"",
        phone:"",
        password:"",
        password_confirmation:"",
        kraPin:"",
        passw:true
    }
    handleRegistration = async (e) => {
        e.preventDefault()
        const response = await axios.post(buildAPIUrl('register'), {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            kraPin: this.state.kraPin,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation
        }).then((res)=>{
            swal({
                title: "Registration successful",
                text: "Your details were saved successfully. We are confirming your details for activation",
                icon: "success",
                button: "Close",
            }).then((res)=>{
                window.location.assign("/")
            })
        }).catch((err)=>{
            console.log(err)
            swal({
                title: "Opps!",
                text: err.response.data.message + ". Your email or phone number already exist",
                icon: "error",
                button: "Close",
            })
        })
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handlepassword = () =>{
        this.setState({
            passw: false
        })
    }
    render() {
        return (
            <>
                <TopNav />
                <div className="container authentication">
                    <div className="row mt-5 d-flex justify-content-center">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                  {/*  <div className="container-fluid  d-flex justify-content-center"> <img src={Logo}/></div>*/}
                                    <div className="container-fluid p-0 mt-2"> <h5 style={{color:"black"}}>Create an account</h5></div>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.handleRegistration}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>First Name</label>
                                                    <input className="form-control" name="firstName" type="text" onChange={this.handleInput} value={this.state.firstName}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>Last Name</label>
                                                    <input className="form-control" name="lastName" type="text" onChange={this.handleInput} value={this.state.lastName}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>Email</label>
                                                    <input className="form-control" name="email" type="email" onChange={this.handleInput} value={this.state.email}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>KRA Pin</label>
                                                    <input className="form-control" name="kraPin" type="text" onChange={this.handleInput} value={this.state.kraPin}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>Location</label>
                                                    <input className="form-control" name="email" type="text"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>Phone</label>
                                                    <input className="form-control" name="phone" type="number" onChange={this.handleInput} value={this.state.phone}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>Password</label>
                                                    <input className="form-control" name="password" type="password" onChange={this.handleInput} value={this.state.password}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>Confirm Password</label>
                                                    <input className="form-control" onKeyUp={this.handlepassword} name="password_confirmation" type="password" onChange={this.handleInput} value={this.state.password_confirmation}/>
                                                    {
                                                        this.state.passw ? "" : <p className="text-danger mt-2">
                                                            {

                                                                this.state.password === this.state.password_confirmation ? "" : "Passwords did not match"
                                                            }
                                                        </p>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <p>Already have a profile? Sign in.</p>
                                        <div className="mb-3">
                                            <button className="btn" style={{background:"#13c56b", color:"white", width:"100%"}}>Sign Up</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default Register