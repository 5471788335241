import React, {Component} from "react";
import Logo from "../../../assets/reizenwhite.png";
import {Link} from "react-router-dom";
import {
    MdAccountBalance,
    MdAddTask, MdCarRental,
    MdEventAvailable, MdLogin,
    MdNoteAdd, MdOutlineAppRegistration,
    MdOutlineEventNote,
    MdOutlineHome,
    MdOutlineHouse, MdOutlineLock, MdPeople
} from "react-icons/md";
class Footer extends Component{
    render() {
        return (
            <footer className="container-fluid pt-5 pb-5">
                <div className="container  mt-3 mb-3">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={Logo} style={{width:"200px"}} />
                            <p className={"mt-5"} style={{fontWeight:"bolder", fontSize:"18px"}}>
                                Reizen Auction is a reputable auctioneering firm giving access to salvages, cars and Properties. Licensed to undertake auctioneer business under the Auctioneers Act No.5 of 1996 of the laws of Kenya.
                            </p>
                        </div>
                        <div className={"col-md-1"}></div>
                        <div className={"col-md-3"}>
                            <h4 className={"mt-5"} style={{fontWeight:"bolder"}}>LOCATION</h4>
                            <h5 className="mt-5">Kenya</h5>
                            <h5>Salama House, Wabera Street</h5>
                            <h5>Nairobi</h5>
                            <h4 className={"mt-5"} style={{fontWeight:"bolder"}}>NEED HELP</h4>
                            <h4 style={{fontWeight:"700"}}>+254 791 230249</h4>
                            <h6 style={{fontWeight:"bolder", fontSize:"18px"}}>info@reizen.auction</h6>
                        </div>
                        <div className={"col-md-1"}></div>
                        <div className={"col-md-3"}>
                            <h4 className={"mt-5"} style={{fontWeight:"bolder"}}>LINKS</h4>
                            <ul style={{listStyleType:"none", padding:"0"}}>
                                <li className="nav-item active">
                                     <Link className="nav-link" to="/">  <MdOutlineHome /> <span style={{marginTop:".2rem", marginLeft:".2rem", padding:"0", fontWeight:"500", fontSize:"18px"}}>Home</span> </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/future-auctions"> <MdOutlineEventNote /> <span style={{marginTop:".2rem", marginLeft:".2rem", padding:"0", fontWeight:"500", fontSize:"18px"}}>Auctions</span> </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/current-auction"> <MdEventAvailable /> <span style={{marginTop:".2rem", marginLeft:".2rem", padding:"0", fontWeight:"500", fontSize:"18px"}}>Current Auction </span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/properties"> <MdOutlineHouse /> <span style={{marginTop:".2rem", marginLeft:".2rem", padding:"0", fontWeight:"500", fontSize:"18px"}}>Properties </span></Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>

            </footer>
        );
    }
}
export default Footer