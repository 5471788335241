import React, {Component} from "react";

class Statistics extends Component{
    render() {
        return (
         /*   <div className="container mt-3 stats">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card p-4">
                            <h6>
                                Salvages
                            </h6>
                            <h3>
                                300
                            </h3>

                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card p-4">
                            <h6>
                              Auctions
                            </h6>
                            <h3 >
                                30
                            </h3>

                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card p-4">
                            <h6>
                               Sold
                            </h6>
                            <h3>
                                30
                            </h3>

                        </div>
                    </div>
                </div>
            </div>*/
            <>
            </>
        )
    }
}
export default Statistics