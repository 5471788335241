import React, {Component} from "react";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";
import UrlBuilder from '../../../Services/UrlBuilder'
import TopNavNew from "./TopNavNew";
import Header from "./Header";
import Statistics from "./Statistics";
import buildAPIUrl from "../../../Services/UrlBuilder";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import swal from "sweetalert";
class AddParticipant extends Component{
    state={
        success:false,
        participants:"",
        firstname:"",
        lastname:"",
        phone:"",
        email:"",
        error:"",
        loading:true
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    saveParticipant = async (e)=>{
        e.preventDefault()
        document.getElementById('submit-button').innerHTML = 'Submitting...'
        const response =  await axios.post(buildAPIUrl('add_participant'), {
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            email:this.state.email,
            phone:this.state.phone,
        }).then((response)=>{
            this.setState({
                name:"",
                phone:"",
                email:"",
                firstname:"",
                lastname:"",
                success: true
            })
            setInterval(()=>{
               this.setState({
                   success:false
               })
            }, 3000)
            document.getElementById('submit-button').innerHTML = 'Save'
        }).catch((e)=>{
            swal({
                title: "Opps!",
                text: "Something happened.",
                icon: "error",
                button: "Close",
            })
            console.log(e)
        })
    }
    async componentDidMount() {
        const response = axios.get(buildAPIUrl('get_participants')).then((res)=>{
            this.setState({
                participants: res.data,
                loading:false
            })
        }).catch((err)=>{

        })
    }
    handleStatus = (param) => {
        console.log("to online")
        const response = axios.put(buildAPIUrl(`change_participant_status/${param}`)).then((res1)=>{
            console.log(param)
            const response = axios.get(buildAPIUrl('get_participants')).then((res)=>{
                this.setState({
                    participants: res.data
                })
            }).catch((err)=>{

            })
        })
    }
    handleStatustoOnline = (param) => {
        const response = axios.put(buildAPIUrl(`change_participant_to_offline/${param}`)).then((res1)=>{
            console.log("to offline")
            const response = axios.get(buildAPIUrl('get_participants')).then((res)=>{
                this.setState({
                    participants: res.data
                })
            }).catch((err)=>{

            })
        })
    }

    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">

                                <div className="card-header">
                                    <h6 className="">Add Participant</h6>

                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.saveParticipant }>

                                        <h6 className={this.state.success ? "alert alert-success" : "d-none" } style={{fontSize:"12px", fontWeight:"400"}}>Participants saved successfully</h6>
                                        <h6 className="text-danger" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.error}</h6>
                                        <div className="mb-3">
                                            <label> First Name</label>
                                            <input required className="form-control" name="firstname" onChange={this.handleInput} value={this.state.firstname}/>
                                        </div>
                                        <div className="mb-3">
                                            <label> Last Name</label>
                                            <input required className="form-control" name="lastname" onChange={this.handleInput} value={this.state.lastname}/>
                                        </div>
                                        <div className="mb-3">
                                            <label> Phone</label>
                                            <input required type="number" className="form-control" name="phone" onChange={this.handleInput} value={this.state.phone}/>
                                        </div>
                                        <div className="mb-3">
                                            <label> Email</label>
                                            <input type="email" required className="form-control" name="email" onChange={this.handleInput} value={this.state.email}/>
                                        </div>
                                        <div className="mb-3">
                                            <button id="submit-button" type="submit" className="btn btn-save">Save</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <h6>Participants</h6>
                                </div>
                                <div className="card-body p-0 m-0">
                                    {
                                        this.state.loading ? <>
                                                <div className="container-fluid d-flex justify-content-center">
                                                    <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                                </div>
                                            </> :
                                            <table className="table table-nowrap">
                                                <tr className="">
                                                    <th>Name</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                    <th>Status</th>
                                                    <th>Change Status</th>
                                                </tr>
                                                {
                                                    this.state.participants ?
                                                        this.state.participants.map((participant) => (

                                                            <tr>
                                                                <td>{participant.first_name} {participant.last_name}</td>
                                                                <td>{participant.phone}</td>
                                                                <td>{participant.email}</td>

                                                                {
                                                                    participant.online === 1 ?
                                                                        <td className="text-success">Online</td> :
                                                                        <td className="text-warning">Offline</td>
                                                                }

                                                                <td>
                                                                    {
                                                                        participant.online === 1 ? <a style={{
                                                                            color: "blue",
                                                                            cursor: "pointer"
                                                                        }}
                                                                                                      onClick={() => this.handleStatus(participant.id)}>Change
                                                                            Status</a> : <a style={{
                                                                            color: "blue",
                                                                            cursor: "pointer"
                                                                        }}
                                                                                            onClick={() => this.handleStatustoOnline(participant.id)}>Change
                                                                            Status</a>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                        : <>
                                                            <h6>No data found</h6>
                                                        </>
                                                }

                                            </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default AddParticipant