import React, {Component} from "react";
import Logo from "../../../assets/reizen.png"
import {Link} from "react-router-dom";
class Header extends Component{
    state = {
        currentpage:window.location.pathname
    }
    handleLogout = (e)=>{
        localStorage.clear()
        window.location.assign('/login')
    }
    render() {
        return(
            <div className="container-fluid border-bottom bg-white navtop">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse d-flex justify-content-start" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Homepage</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/user-current-auction" >Current Auction</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/user-future-auction" >Future Auction</Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" onClick={this.handleLogout}>Logout</a>
                                </li>

                                {/*<li className="nav-item">
                                    <a className="nav-link" href="#">Current Auction</a>
                                </li>*/}
                               {/* <li className="nav-item">
                                    <a className="nav-link" href="#">Past Auctions</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Upcoming Auctions</a>
                                </li>*/}
                            </ul>
                        </div>
                    </nav>
                </div>

            </div>
        )
    }
}
export default Header