import React, {Component} from "react";
import TopNav from "../TopNav";
import {Link} from "react-router-dom";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import swal from "sweetalert";
class ResetPassword extends Component{
    state = {
        newpassword:"",
        password:"",
        success:"",
        oldpassword:"",
        passw:true
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleReset = (e) => {
        e.preventDefault()
        const response = axios.put(buildAPIUrl('reset-password'), {
            email:localStorage.getItem('email'),
            password:this.state.password
        }).then((res)=>{
            swal({
                title: "Successful",
                text: "Your password was updated successfully",
                icon: "success",
                button: "Close",
            }).then((res)=>{
                window.location.assign("/login")
            })
        }).catch((error)=>{
            swal({
                title: "Opps!",
                text: "Your password was not updated",
                icon: "success",
                button: "Close",
            }).then((res)=>{
                window.location.assign("/reset-password")
            })
        })
    }
    handlepassword = () =>{
        this.setState({
            passw: false
        })
    }
    render() {
        return (
            <div>
                <>
                    <TopNav />
                    <div className="container authentication">
                        <div className="row mt-5 d-flex justify-content-center">
                            <div className="col-md-5">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 style={{color:"#1a3760"}}>Reset Password</h4>
                                        <p style={{fontSize: "14px"}} className="text-success" >{this.state.success}</p>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={this.handleReset}>
                                            <div className="mb-3">
                                                <label>Old Password (Sent to your email)</label>
                                                <input className="form-control"  required  name="oldpassword" type="password" onChange={this.handleInput} value={this.state.oldpassword}/>
                                            </div>
                                            <div className="mb-3">
                                                <label>New Password</label>
                                                <input className="form-control"  required  name="newpassword" type="password" onChange={this.handleInput} value={this.state.newpassword}/>
                                            </div>
                                            <div className="mb-3">
                                                <label>Confirm New Password</label>
                                                <input className="form-control" onKeyUp={this.handlepassword} required  name="password" type="password" onChange={this.handleInput} value={this.state.password}/>
                                                {
                                                    this.state.passw ? "" : <p className="text-danger mt-2">
                                                        {

                                                            this.state.newpassword === this.state.password ? "" : "Passwords did not match"
                                                        }
                                                    </p>
                                                }
                                            </div>
                                            <div className="mb-3">
                                                <button className="btn" style={{background:"#13c56b", color:"white", width:"100%"}}>Update Password</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            </div>
        );
    }
}
export default ResetPassword