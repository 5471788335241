import React, {Component, useState} from "react";
import TopNav from "../TopNav";
import Header from "../Header";
import Carimg from "../../../assets/car1.jpg"
import {FaWhatsapp} from "react-icons/fa"
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import HorizontalGallery from "react-dynamic-carousel";
import {useParams} from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import ReactWhatsapp from 'react-whatsapp';
import property from "./Property";
import Properties from "./Properties";
import Footer from "./Footer";

class PropertyDetails extends Component{
    state = {
        property_id: this.props.match.params.id,
        auction_id: localStorage.getItem('auction_id'),
        property: "",
        images:[],
        bidAmount:"",
        highestBid:"",
        bids:[],
        loading: true,
        properties:[]
    }
    async componentDidMount(){

            const response = await axios.get(buildAPIUrl(`property-details/${this.state.property_id}`)).then((res)=>{
                console.log(res)
                this.setState({
                    property: res.data,
                    images:res.data.propertyimages,
                    loading:false
                })
            })
        const response1 = await axios.get(buildAPIUrl(`get-all-properties`)).then((res1)=>{
            console.log(res1, "properties")
            this.setState({
                properties: res1.data
            })
        }).catch((error)=>{

        })

    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleLogin = () => {
        window.location.assign('/login')
    }
    render() {

        return (
            <>
                <TopNav />
                <Header />
                {
                    this.state.loading ? <>
                            <div className="container-fluid d-flex justify-content-center">
                                <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                            </div>
                        </> :
                        <div className="container mt-5 border item-details p-3" style={{borderRadius:"10px"}}>
                            <div className="container">

                                <div className="row mt-5">
                                    <div className="col-md-6 details-img">
                                        <div className="container-fluid border">
                                            <Carousel>
                                                {
                                                    this.state.images.map((image, key) => (
                                                        <Carousel.Item>
                                                            <img
                                                                className="d-block w-100"
                                                                src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}
                                                                alt="First slide"
                                                            />

                                                        </Carousel.Item>
                                                        /* <div className={key === 0 ? 'active carousel-item' : 'carousel-item' }>
                                                             <img className="d-block w-100" src= alt="First slide" />
                                                         </div>*/
                                                    ))
                                                }

                                            </Carousel>
                                        </div>
                                        <div className="container-fluid mt-2 ">
                                            <div className={"row"}>
                                                {
                                                    this.state.images.map((image, key) => (
                                                        <div className={"col-md-3 px-1"}>
                                                            <img
                                                                className="d-block w-100"
                                                                src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}
                                                                alt="First slide"
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6 m-0">
                                        <div className="bg-white pt-4">
                                            <div className="row top-details">
                                                <div className="col-md-12">
                                                    <div className={"row d-flex align-items-center"}>
                                                        <div className={"col-md-6"}>
                                                            <h3 className={"m-0 p-0"}>{this.state.property.propertyName}</h3>
                                                        </div>
                                                        <div className={"col-md-6 d-flex justify-content-end"}>
                                                            <span style={{background:"rgba(224,224,224,0.68)", padding:"10px 20px", borderRadius:"5px"}}>
                                                                <h3 className={"m-0 p-0"} style={{color:"#f38e24"}}>
                                                                KES {(+this.state.property.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            </h3>

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className={"container-fluid p-0"}>
                                                        <ul className={"p-0"}>
                                                            <li>
                                                                <h6 className={"light"}> {this.state.property.nature}</h6>
                                                            </li>
                                                            <li >
                                                                <h6 className={"light"}> {this.state.property.location}</h6>
                                                            </li>
                                                            <li>
                                                                <h6 className={"light"}> {this.state.property.price}</h6>
                                                            </li>
                                                            <li>
                                                                <h6 className={"light"}> KES {(+this.state.property.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>
                                                            </li>

                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className=" m-0 mt-2 p-0 mb-3">
                                            <div className="card-body">
                                                <table
                                                    className="table m-0">
                                                    <tr>
                                                        <td>Property Name</td>
                                                        <td>{this.state.property.propertyName}</td>

                                                    </tr>
                                                    <tr>
                                                        <td>Size</td>
                                                        <td>{this.state.property.size}</td>

                                                    </tr>
                                                    <tr>
                                                        <td>Location</td>
                                                        <td>{this.state.property.location}</td>

                                                    </tr>
                                                    <tr>
                                                        <td>Property Type</td>
                                                        <td>{this.state.property.nature}</td>

                                                    </tr>
                                                    <tr>
                                                        <td>Price</td>
                                                        <td>KES {(+this.state.property.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>

                                                    </tr>
                                                </table>
                                                <ReactWhatsapp className="btn" style={{background:"#ff9089", borderRadius:"0"}} number="254725757808" message={`Hi there. I would like to enquire about ${this.state.property.propertyName}`} > Click to request more information</ReactWhatsapp>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                }
                <div className={"container-fluid bg-white pt-5"}>
                    <Properties type={"landing"} properties={this.state.properties} />
                </div>
                <Footer/>
            </>

        )

    }
}


export default PropertyDetails