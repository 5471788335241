import React, {Component} from "react";
import Logo from "../../../assets/reizen.png"
import {Link} from "react-router-dom";
import {
    MdAccountBalance,
    MdAddTask,
    MdCarRental,
    MdEventAvailable, MdGroup,
    MdNoteAdd,
    MdOutlineHome,
    MdOutlineHouse, MdOutlineLock,
    MdPeople
} from "react-icons/md";
class Header extends Component{
    handleLogout = (e)=>{
        localStorage.clear()
        window.location.assign('/login')
    }
    render() {
        return(
            <div className="container-fluid border-bottom bg-white navtop">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse d-flex justify-content-start" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">

                                <li className="nav-item active">
                                    <Link className="nav-link" to="/admin-dashboard"><MdOutlineHome /> Dashboard</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                       data-bs-toggle="dropdown" aria-expanded="false">
                                        <MdAddTask /> Add
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link className="nav-link" to="/admin-add-salvages"> <MdAddTask /> Add Salvages</Link></li>
                                        <li> <Link className="nav-link" to="/admin-current-auction"> <MdEventAvailable /> Current Auction</Link></li>
                                        <li><Link className="nav-link" to="/add-property"> <MdOutlineHouse /> Add Property</Link></li>
                                        <li> <Link className="nav-link" to="/add-bids"> <MdNoteAdd /> Add Bids</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#"> <MdCarRental /> Cars</a>
                                </li>
                                <li className="nav-item">

                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/add-participant"> <MdPeople /> Participants</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/customers-list"> <MdGroup/> Customers</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/addcompany"> <MdAccountBalance /> Our client</Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" onClick={this.handleLogout}> <MdOutlineLock /> Logout</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>

            </div>
        )
    }
}
export default Header