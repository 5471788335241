import React, {Component} from "react";
import Dropzone from 'react-dropzone'
import TopNavNew from "./TopNavNew";
import Header from "./Header";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
class UploadImages extends Component{
    state = {
        images: [],
        success: false,
        imageslist:[],
        deleted:false
    }
    handleChange = (e)=>{
        this.setState({
            images:e.target.files[0]
        })
    }
    acceptedFiles=(response) => {
        console.log(response)
        this.setState({
        })
    }
    uploadImages = async (res) => {
        res.preventDefault()
        const formdata = new FormData()
        formdata.append('image', this.state.images)
        formdata.append('salvage_id', this.props.match.params.id)
        const response = await axios({
            method: "post",
            url: buildAPIUrl('upload-salvage-images'),
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },

        }).then((resp)=>{
            console.log("Uploaded Successfully")
            console.log(resp)
            this.setState({
                success:true
            })
            setTimeout(()=>{
                window.location.reload()
            }, 3000)
        })
    }
    componentDidMount() {
        let salvageid = this.props.match.params.id
        const response = axios.get(buildAPIUrl(`get-salvage-images/${salvageid}`)).then((res)=>{
            console.log(res)
            this.setState({
                imageslist:res.data
            })
        }).catch((error)=>{

        })
    }
    deleteImage = (id) =>{
        const response = axios.post(buildAPIUrl(`delete-image/${id}`)).then((res)=>{
            this.setState({
                deleted:true
            })
            setTimeout(()=>{
                this.setState({
                    deleted:false
                })
                window.location.reload()
            }, 3000)
        }).catch((error)=>{

        })
    }

    render() {

        return (
            <>
                <TopNavNew />
                <Header />
                <div className="row m-5">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header"> Upload Images
                                <h6 className="text-success" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.success}</h6>
                            </div>
                            <div className="card-body">
                                <div className={this.state.deleted ? "alert alert-success" :"d-none"}>Image deleting...</div>
                                <div className={this.state.success ? "alert alert-success" :"d-none"}>Image uploading...</div>
                                <form onSubmit={this.uploadImages} encType="multipart/form-data">
                                    <div className="form-group">
                                        <label>Attach File</label>
                                        <input type="file" multiple value={""} className="form-control" name="images" onChange={this.handleChange}/>
                                    </div>
                                    <div className="container mt-2  d-flex align-items-center justify-content-end">
                                        <button className="btn btn-secondary">Upload</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        {
                            this.state.imageslist ?   <div className="row">
                                {
                                    this.state.imageslist.map((image)=>(
                                <div className="col-md-3">
                                    <div className="card-body">
                                        <div className="car-img">

                                                    <>
                                                        <img src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}/>
                                                        <button className="btn btn-outline-danger mt-2" onClick={()=>this.deleteImage(image.id)}>Delete</button>
                                                    </>



                                        </div>

                                    </div>


                                </div>
                                    ))

                                }
                            </div> : <p>No images uploaded yet</p>
                        }

                    </div>
                </div>



            </>
        )
    }
}
export default UploadImages