import React, {Component} from 'react'
import TopNav from "./TopNav";
import Header from "./Header";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import Salvages from "./components/Salvages";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import Property from "./components/Property";

class PropertyFutureAuction extends Component{
    state = {
        properties:"",
        loading: true
    }
    async componentDidMount() {
        let auctionid = this.props.match.params.id
        const response = await axios.get(buildAPIUrl(`properties_in_future_auction/${auctionid}`)).then((res)=>{
            this.setState({
                properties: res.data.salvages,
                loading:false
            })
        })
    }
    render() {
        return (
            <>
                <TopNav />
                <Header />
                <div className="container mt-5">
                    <h3 className="section-title">Future Auction</h3>
                </div>
                <Property properties={this.state.properties}/>
            </>
        )
    }
}
export default PropertyFutureAuction