import React, {Component} from "react";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";
import UrlBuilder from '../../../Services/UrlBuilder'
import buildAPIUrl from "../../../Services/UrlBuilder";
import {Link} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
class Auctions extends Component{
    state={
        change: new Date(),
        startdate: new Date(),
        auctionTitle:"",
        auctionDate:"",
        auctionStartTime:"",
        auctionEndTime:"",
        success: "",
        auctions:"",
        auctionType:"",
        loading:true,
        delete:false,
        auctionCategory:"",
        user_id:"",
        companies:"",
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    saveauction = async (e)=>{
        e.preventDefault()
        const response =  await axios.post(buildAPIUrl('saveauction'), {
            auctionTitle:this.state.auctionTitle,
            auctionDate: new Date(),
            auctionStartTime: this.state.auctionStartTime,
            auctionEndTime: this.state.auctionEndTime,
            auctionType:this.state.auctionType,
            auctionCategory:this.state.auctionCategory,
            user_id:this.state.user_id
        }).then((response)=>{
            this.setState({
                change: new Date(),
                startdate: new Date(),
                auctionTitle:"",
                auctionDate:"",
                auctionStartTime:"",
                auctionEndTime:"",
                success: "Auction Created Successfully",
                user_id:""
            })
           window.location.reload()
        }).catch((e)=>{

        })
    }
   componentDidMount (){
        const response =  axios.get(buildAPIUrl('all-auctions')).then((res)=>{
            console.log(res)
            this.setState({
                auctions: res.data,
                loading:false
            })
        })
       const response1 =  axios.get(buildAPIUrl('getcompanies')).then((res)=>{
           this.setState({
               companies: res.data
           })
       }).catch((err)=>{

       })
    }
    handleDelete = async (auctionid) =>{
        const resp = await axios.post(buildAPIUrl(`delete-auction/${auctionid}`)).then(()=>{
            this.setState({
                delete:true
            })
            setInterval(()=>{
                window.location.reload()
            }, 3000)
        })
    }
    render() {
        return (
            <div className="container-fluid mt-5 auctions px-5 pb-5 mb-5">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card px-3" >
                            <div className="card-body">
                                <h5 className="mt-4 mb-4">Create Auction</h5>
                                <h6 className="text-success" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.success}</h6>
                                <form onSubmit={this.saveauction}>
                                    <div className="mb-3">
                                        <label>Auction Title</label>
                                        <input className="form-control" name="auctionTitle" onChange={this.handleInput} value={this.state.auctionTitle}/>
                                    </div>
                                    {/*<div className="mb-3">
                                        <label>Auction Date </label>
                                        <input className="form-control" type="datetime-local" name="auctionDate"  onChange={this.handleInput} value={this.state.auctionDate} />

                                    </div>*/}
                                    <div className="mb-3">
                                        <label>Auction Start Time</label>
                                        <input type="datetime-local" className="form-control" name="auctionStartTime"  onChange={this.handleInput} value={this.state.auctionStartTime}/>
                                    </div>
                                    <div className="mb-3">
                                        <label>Auction End Time</label>
                                        <input type="datetime-local" className="form-control" name="auctionEndTime"  onChange={this.handleInput} value={this.state.auctionEndTime}/>
                                    </div>
                                    <div className="mb-3">
                                        <label>Auction Type</label>
                                        <select  className="form-control" required name="auctionType"  onChange={this.handleInput} value={this.state.auctionType}>
                                            <option value="">---Select Auction Type---</option>
                                            <option value="1">Salvages</option>
                                            <option value="2">Properties</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label>Auction Category</label>
                                        <select  className="form-control" required name="auctionCategory"  onChange={this.handleInput} value={this.state.auctionCategory}>
                                            <option value="">---Select Auction Category---</option>
                                            <option value="1">With a deposit</option>
                                            <option value="2">With participation fees</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label>Owner</label>
                                        <select className="form-control" name="user_id" onChange={this.handleInput} value={this.state.user_id}>
                                            <option>---Select Company---</option>
                                            {
                                                this.state.companies ?

                                                    this.state.companies.map((company)=>(
                                                        <option value={company.id}>{company.first_name + " " + company.last_name}</option>
                                                    ))

                                                    : <option disabled>No Companies Loaded</option>
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-save">Save</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body px-5">
                                <h5 className="mt-4 mb-4" >Auctions</h5>
                                {
                                    this.state.delete ? <h6 className="alert alert-danger"> Auction deleted successfully</h6> :""
                                }
                                {
                                    this.state.loading ? <>
                                            <div className="container-fluid d-flex justify-content-center">
                                                <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                            </div>
                                        </> :
                                        <table className="table table-nowrap table-centered align-middle">
                                            <tr className="options-table-header border">
                                                <th>Auction Title</th>
                                                <th>Start Time</th>
                                                <th>End time</th>
                                                <th>Add</th>
                                                <th>Options</th>
                                            </tr>
                                            {
                                                this.state.auctions ?
                                                    this.state.auctions.map((auction) => (
                                                            <tr >
                                                                <td>{auction.auctionTitle}</td>
                                                                <td>{auction.auctionStartTime}</td>
                                                                <td>{auction.auctionEndTime}</td>
                                                                <td>
                                                                    {
                                                                        auction.auctionType===1 ?

                                                                            <Link to={`add-salvage-to-auction/${auction.id}`}>
                                                                                Add Salvages
                                                                            </Link>
                                                                            :
                                                                            <Link to={`add-property-to-auction/${auction.id}`}>
                                                                                Add Properties
                                                                            </Link>
                                                                    }

                                                                    <br />
                                                                    {
                                                                        auction.auctionCategory===1 ?  <Link className="text-secondary" to={`deposit-participant/${auction.id}`}>
                                                                            Add Participants
                                                                        </Link> : ""
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Link to={`/auction-details/${auction.id}`}>
                                                                        View Auction
                                                                    </Link>
                                                                    <br/>
                                                                    <Link className="text-secondary" to={`/edit-auction/${auction.id}`}>
                                                                        Edit Auction
                                                                    </Link>
                                                                    <br/>
                                                                    <a style={{cursor:"pointer"}} className="text-danger" onClick={()=>this.handleDelete(auction.id)} >Delete</a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )

                                                    : <p>No Auctions Found</p>
                                            }

                                        </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Auctions