import React, {Component} from "react";
import Logo from "../../../assets/reizen.png"
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import TopNav from "../TopNav";
import swal from "sweetalert";
import {Link} from "react-router-dom";

class Login extends Component{
    state = {
        email:"",
        password:"",
        success:""
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleLogin = async (e) =>{
        e.preventDefault()
        const response = await axios.post(buildAPIUrl('login'), {
            email:this.state.email,
            password: this.state.password
        }).then((res)=>{
            this.setState({
                success:"Login successful. Redirecting..."
            })
            console.log(res)
            localStorage.setItem('first_name', res.data.firstname)
            localStorage.setItem('last_name', res.data.last_name)
            localStorage.setItem('email', res.data.email)
            localStorage.setItem('uniq', res.data.uniq)
            localStorage.setItem('item', res.data.id)
            localStorage.setItem('expire', new Date().getTime() + (2 * 1000 * 60 * 60))
            if(res.data.status===0){
                if(res.data.userType === 1){
                    this.props.history.push('/admin-dashboard')
                } else if (res.data.userType === 0){
                    this.props.history.push('/user-dashboard')
                } else if (res.data.userType === 4){
                    this.props.history.push('/company-dashboard')
                }
            } else if(res.data.status===3){
                window.location.assign('/reset-password')
            }

        }, ).catch((error)=>{
            console.log(error)
            swal({
                title: "Opps!",
                text: error.response.data.error,
                icon: "error",
                button: "Close",
            }).then((res)=>{
                window.location.assign("/login")
            })
        })
    }
    render() {
        return (
            <>
                <TopNav />
                <div className="container authentication">
                    <div className="row mt-5 d-flex justify-content-center">
                        <div className="col-md-5">
                            <div className="card px-5 border">
                                <div className="card-body pt-5 pb-5">

                                    <p className={"text-center px-5"}>
                                        Enter your email address and password to access your account.
                                    </p>
                                    <p style={{fontSize: "14px"}} className="text-success" >{this.state.success}</p>
                                    <form onSubmit={this.handleLogin}>
                                        <div className="mb-3 mt-5">
                                            <label>Email</label>
                                            <input className="form-control"  required  name="email" type="email" onChange={this.handleInput} value={this.state.email}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Password</label>
                                            <input className="form-control" required  name="password" type="password" onChange={this.handleInput} value={this.state.password}/>
                                        </div>
                                        <div className="mb-3 mt-4">
                                            <p> <Link to="/forgot" style={{color:"#5F9DF7", fontWeight:"500", textDecoration:"none"}} > Forgot password </Link></p>
                                            <button className="btn" style={{background:"#5F9DF7", color:"white", width:"100%",height:"40px", borderRadius:"0"}}>Sign In</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default Login