import React, {Component} from "react";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";
import UrlBuilder from '../../../Services/UrlBuilder'
import TopNavNew from "./TopNavNew";
import Header from "./Header";
import Statistics from "./Statistics";
import buildAPIUrl from "../../../Services/UrlBuilder";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
class AddDepositParticipant extends Component{
    state={
        participants:[],
        name:"",
        phone:"",
        email:"",
        error:"",
        salvages:[],
        salvage:"",
        participant:"",
        bidAmount:"",
        loading:true,
        success:false,
        depositParticipants:[],
        items:"",
        deleted:false
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
componentDidMount() {
        const response = axios.get(buildAPIUrl('get_deposit_participants')).then((res)=>{
            this.setState({
                participants: res.data,
                loading:false
            })
        }).catch((err)=>{

        })

        const response2 = axios.get(buildAPIUrl(`get-participants-in-auction/${this.props.match.params.id}`)).then((res)=>{
            this.setState({
                depositParticipants:res.data
            })
        })
    }
    handleStatus = (param) => {
        const response = axios.put(buildAPIUrl(`change_participant_status/${param}`)).then((res1)=>{
            const response = axios.get(buildAPIUrl('get_participants')).then((res)=>{
                this.setState({
                    participants: res.data
                })

            }).catch((err)=>{

            })
        })
    }
    saveParticipant = async (e) => {
        e.preventDefault()
        document.getElementById('submit-button').innerHTML = 'Submitting...'
        const res = await axios.post(buildAPIUrl('add-participant-to-auction'), {
            salvage_id: 0,
            user_id: this.state.name,
            auction_id:this.props.match.params.id,
            items:this.state.items
        }).then((response)=>{
            this.setState({
                bidAmount:"",
                salvage:"",
                participant:"",
                name:"",
                success:true,
                items:""
            })

            setInterval(()=>{
                this.setState({
                    success:false
                })
                window.location.reload()
            }, 1500)
            document.getElementById('submit-button').innerHTML = 'Submit'
        }).catch((err)=>{
            console.log(err)
        })
    }
    handleDeleteParticipant = (id) =>{
        const deleteparticipant = axios.post(buildAPIUrl(`delete-participant-from-auction/${id}`)).then((res)=>{
            this.setState({
                deleted:true
            })
            setTimeout(()=>{
                this.setState({
                    deleted:false
                })
                window.location.reload()
            }, 1500)
        }).catch((error)=>{

        })
    }
    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="">Add Participant</h6>
                                    <h6 className="text-success" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.success}</h6>
                                    <h6 className="text-danger" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.error}</h6>
                                </div>
                                <div className="card-body">
                                    <h6 className={this.state.success ? "alert alert-success" : "d-none" } style={{fontSize:"12px", fontWeight:"400"}}>Adding participant...</h6>
                                    <form onSubmit={this.saveParticipant }>
                                        <div className="mb-3">
                                            <label>Select Participant</label>
                                            <select className="form-control" onChange={this.handleInput} name="name" value={this.state.name}>
                                                <option>---Select participant---</option>
                                                {
                                                    this.state.participants.map((participant)=>(
                                                            <option value={participant.id}>{participant.first_name} {participant.last_name}</option>
                                                        )

                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label> Number of items</label>
                                          <input type={"text"} name={"items"} onChange={this.handleInput} value={this.state.items} className={"form-control"}/>
                                        </div>
                                        <div className="mb-3">
                                            <button type="submit" id="submit-button" className="btn btn-save">Save</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <h6>Participants</h6>
                                </div>
                                <div className="card-body p-0 m-0">
                                    <div className={this.state.deleted ? "alert alert-danger mt-3": "d-none"}>
                                       Deleting participant...
                                    </div>
                                    {
                                        this.state.loading ? <>
                                                <div className="container-fluid d-flex justify-content-center">
                                                    <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                                </div>
                                            </> :
                                            <table className="table table-nowrap">
                                                <tr className="">
                                                    <th>Name</th>
                                                    <th>Items</th>

                                                    <th>Delete</th>

                                                </tr>
                                                {
                                                    this.state.depositParticipants.length > 0 ?
                                                        this.state.depositParticipants.map((participant) => (
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        participant.user.first_name + " " + participant.user.last_name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        participant.items
                                                                    }
                                                                </td>

                                                                <td>
                                                                    <a onClick={()=>this.handleDeleteParticipant(participant.id)} className={"text-danger"} style={{cursor:"pointer"}}> Delete</a>
                                                                </td>
                                                            </tr>
                                                        ))
                                                        : <>
                                                            <h6>No data found</h6>
                                                        </>
                                                }

                                            </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default AddDepositParticipant