import React, {Component} from "react";
import TopNavNew from "../components/TopNavNew";
import Header from "../components/Header";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {Link} from "react-router-dom";
class AddPropertyToAuction extends Component{
    state = {
        success: "",
        properties:[],
        salvage_id:"",
        salvageDetails:"",
        salvagesInAuction:[],
        deleted:false
    }
    handleInput = (e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async componentDidMount (){
        const response  = await axios.get(buildAPIUrl('get-all-properties')).then((res)=>{
            console.log(res)
            this.setState({
                properties:res.data
            })
        });
        const response1 = await axios.get(buildAPIUrl(`get-auction-details/${this.props.match.params.id}`)).then((res1)=>{
            this.setState({
                salvageDetails:res1.data
            })
        })
        const response2 = await axios.get(buildAPIUrl(`get-properties-in-auction/${this.props.match.params.id}`)).then((res2)=>{
            console.log(res2)
            this.setState({
                salvagesInAuction:res2.data.salvages
            })
        })
    }
    addSalvagetoAuction = async (e) =>{
        e.preventDefault()
        const response = await axios.post(buildAPIUrl('add-property-to-auction'), {
            auction_id:this.props.match.params.id,
            salvage_id:this.state.salvage_id,
        }).then((res)=>{
            console.log(res)
            this.setState({
                success:res.data
            })
            window.location.reload()
        }).catch((err)=>{
            console.log(err)
        })

    }
    handleDeleteFromAuction = (id)=>{
        const response = axios.post(buildAPIUrl(`delete-property-from-auction/${id}`)).then((res)=>{
            this.setState({
                deleted:true
            })
            setTimeout(()=>{
                this.setState({
                    deleted:false
                })
                window.location.reload()
            }, 2000)
        }).catch((error)=>{

        })
    }
    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="row m-5">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header"> Add Salvage to Auction
                                <h6 className="text-success" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.success}</h6>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.addSalvagetoAuction} encType="multipart/form-data">
                                    <div className="form-group">
                                        <label>Select Salvage</label>
                                        <select className="form-control" onChange={this.handleInput} value={this.state.salvage_id} name="salvage_id">
                                            <option>---Select Property---</option>
                                            {
                                                this.state.properties.map((property)=>(
                                                    <option value={property.id}>{property.propertyName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <button className="btn btn-save mt-2">Upload</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">

                        <div className="container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h6>Title: <span>{this.state.salvageDetails.auctionTitle}</span> </h6>
                                        </div>
                                        <div className="col-md-6">
                                            <h6>Date:  <span>{this.state.salvageDetails.auctionDate}</span></h6>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <h6>
                                                Start Time:  <span>{this.state.salvageDetails.auctionStartTime}</span>
                                            </h6>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <h6>
                                                End Time:  <span>{this.state.salvageDetails.auctionEndTime}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="container mt-5">
                            <div className={this.state.deleted ?  "alert alert-danger" :  "d-none"}>
                                Deleting property
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h6>Properties in this Auction</h6>
                                </div>
                                <div className="card-body">
                                    <table className="table table-nowrap table-centered align-middle">
                                        <tr className="options-table-header">
                                            <th>Property Name</th>
                                            <th>Location</th>
                                            <th>Reserve Price</th>
                                            <th>Size</th>
                                            <th>Delete</th>

                                        </tr>
                                        {
                                            this.state.salvagesInAuction ? <>
                                                {
                                                    this.state.salvagesInAuction.map((salvage)=>(
                                                        <tr>
                                                            <td>{salvage.propertyName}</td>
                                                            <td>{salvage.location}</td>
                                                            <td>{salvage.price}</td>
                                                            <td>{salvage.size}</td>
                                                            <td><h6 className={"text-danger"} style={{cursor:"pointer"}} onClick={()=>this.handleDeleteFromAuction(salvage.id)}>Delete</h6> </td>
                                                        </tr>

                                                    ))
                                                }
                                            </> : <>
                                                <p>There are no salvages in this auction</p>
                                            </>
                                        }
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default AddPropertyToAuction