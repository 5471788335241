import React, {Component} from "react";
import TopNav from "../components/TopNavNew";
import Header from "../components/Header";

class Future extends Component{
    render() {
        return (
            <>
                <TopNav />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="mt-3">Future Auctions</h6>
                                </div>
                                <div className="card-body p-0 m-0">
                                    <table className="table table-nowrap table-centered align-middle">
                                        <tr className="options-table-header">
                                            <th>Auction Title</th>
                                            <th>Date</th>
                                            <th>Start Time</th>
                                            <th>End time</th>
                                            <th> Add </th>
                                            <th>Options</th>
                                        </tr>
                                        <tr>
                                            <td>Reizen Auction</td>
                                            <td>26/04/2022</td>
                                            <td>10:00</td>
                                            <td>12:00</td>
                                            <td>
                                                Add Salvages
                                            </td>
                                            <td>

                                                Edit
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Reizen Auction</td>
                                            <td>26/04/2022</td>
                                            <td>10:00</td>
                                            <td>12:00</td>
                                            <td>
                                                Add Salvages
                                            </td>
                                            <td>

                                                Edit
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Future