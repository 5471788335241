import React, {Component} from "react";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";
import UrlBuilder from '../../../Services/UrlBuilder'
import TopNavNew from "./TopNavNew";
import Header from "./Header";
import Statistics from "./Statistics";
import buildAPIUrl from "../../../Services/UrlBuilder";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
class AddBids extends Component{
    state={
        success:"",
        participants:[],
        name:"",
        phone:"",
        email:"",
        error:"",
        salvages:[],
        salvage:"",
        participant:"",
        bidAmount:"",
        loading:true
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async componentDidMount() {
        const response = axios.get(buildAPIUrl('get_participants')).then((res)=>{
            console.log("participants", res.data)

            this.setState({
                participants: res.data,
                loading:false
            })
        }).catch((err)=>{

        })
        const response1  = axios.get(buildAPIUrl('current-auction')).then((res)=>{
            console.log("salvages", res)
            localStorage.setItem('auctionid', res.data.auction_id)
            this.setState({
                salvages: res.data.salvages
            })
        })
    }
    handleStatus = (param) => {
        const response = axios.put(buildAPIUrl(`change_participant_status/${param}`)).then((res1)=>{
            const response = axios.get(buildAPIUrl('get_participants')).then((res)=>{
                this.setState({
                    participants: res.data
                })
            }).catch((err)=>{

            })
        })
    }
    saveBid = async (e) => {
        e.preventDefault()
        document.getElementById('submit-button').innerHTML = 'Submitting...'
        const res = await axios.post(buildAPIUrl('save-bid'), {
            salvage_id: this.state.salvage,
            auction_id: localStorage.getItem('auctionid'),
            bidAmount: this.state.bidAmount,
            name: this.state.name,
            email: "",
        }).then((response)=>{
            this.setState({
                bidAmount:"",
                salvage:"",
                participant:"",
            })
            document.getElementById('submit-button').innerHTML = 'Submit'
        }).catch((err)=>{
            console.log(err)
        })
    }

    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="">Add Participant</h6>
                                    <h6 className="text-success" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.success}</h6>
                                    <h6 className="text-danger" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.error}</h6>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.saveBid }>
                                        <div className="mb-3">
                                            <label>Select Participant</label>
                                            <select className="form-control" onChange={this.handleInput} name="name" value={this.state.name}>
                                                <option>---Select participant---</option>
                                                {
                                                    this.state.participants.map((participant)=>(
                                                            <option value={participant.first_name + " " + participant.last_name}>{participant.first_name} {participant.last_name}</option>
                                                        )

                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label> Select Salvage</label>
                                            <select className="form-control" onChange={this.handleInput} name="salvage" value={this.state.salvage}>
                                                <option>---Select Salvage---</option>
                                                {
                                                    this.state.salvages.map((salvage)=>(
                                                        <option value={salvage.id}>{salvage.regNo}</option>
                                                        )

                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label> Enter Bid Amount</label>
                                            <input type="number" required className="form-control" name="bidAmount" onChange={this.handleInput} value={this.state.bidAmount}/>
                                        </div>
                                        <div className="mb-3">
                                            <button type="submit" id="submit-button" className="btn btn-save">Save</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <h6>Participants</h6>
                                </div>
                                <div className="card-body p-0 m-0">
                                    {
                                        this.state.loading ? <>
                                                <div className="container-fluid d-flex justify-content-center">
                                                    <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                                </div>
                                            </> :
                                            <table className="table table-nowrap">
                                                <tr className="">
                                                    <th>Name Name</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                    <th>Status</th>
                                                    <th>Change Status</th>
                                                </tr>
                                                {
                                                    this.state.participants ?
                                                        this.state.participants.map((participant) => (

                                                            <tr>
                                                                <td>{participant.name}</td>
                                                                <td>{participant.phone}</td>
                                                                <td>{participant.email}</td>
                                                                {
                                                                    participant.status !== "0" ?
                                                                        <td className="text-success">Online</td> :
                                                                        <td className="text-warning">Offline</td>
                                                                }

                                                                <td><a style={{color: "blue", cursor: "pointer"}}
                                                                       onClick={() => this.handleStatus(participant.id)}>Change
                                                                    Status</a></td>
                                                            </tr>
                                                        ))
                                                        : <>
                                                            <h6>No data found</h6>
                                                        </>
                                                }

                                            </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default AddBids