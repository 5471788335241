import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import TopNav from "./TopNav";
import Header from "../../components/Customer/components/Header";
import {Link} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import TopNavNew from "../Customer/components/TopNavNew";

class UserFutureAuction extends Component{
    state = {
        auctions: "",
        loading: true
    }
    async componentDidMount() {
        const response =  axios.get(buildAPIUrl('future-auctions')).then((res)=>{
            console.log(res)
            this.setState({
                auctions: res.data,
                loading:false
            })
        })
    }

    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="container mt-5">
                    {
                        this.state.loading ? <>
                                <div className="container-fluid d-flex justify-content-center">
                                    <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                </div>
                            </> :
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h6>Future Auctions</h6>
                                    </div>
                                    <div className="card-body p-0 m-0">
                                        <table className="table table-nowrap table-centered align-middle">
                                            <tr className="options-table-header">
                                                <th>Auction Title</th>
                                                <th>Start Time</th>
                                                <th>End time</th>
                                                <th>Options</th>
                                            </tr>
                                            {
                                                this.state.auctions.length > 0 ?
                                                    this.state.auctions.map((auction) => (
                                                        <tr>
                                                            <td>{auction.auctionTitle}</td>
                                                            <td>{auction.auctionStartTime}</td>
                                                            <td>{auction.auctionEndTime}</td>
                                                            <td>
                                                                {
                                                                    auction.auctionType===1 ? <Link to={`future-auction-list/${auction.id}`}>View
                                                                            Salvages</Link>
                                                                        :
                                                                        <Link to={`future-auction-property-list/${auction.id}`}>View
                                                                            Properties</Link>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))

                                                    :
                                                    <>
                                                        <tr>
                                                            <td colSpan="5">No upcoming auctions</td>
                                                        </tr>

                                                    </>

                                            }

                                        </table>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </>
        )
    }
}
export  default UserFutureAuction