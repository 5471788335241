import React, {Component} from "react";
import TopNav from "../components/TopNavNew";
import Header from "../components/Header";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {Link} from "react-router-dom";
import {FaRegEdit} from 'react-icons/fa'

class EditSalvage extends Component{
    state = {
        companies:"",
        salvage:"",
        make:"",
        model:"",
        reserve:"",
        companyReserve:"",
        company_id:"",
        category:"",
        regNo:"",
        location:"",
        salvages:""
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async componentDidMount() {
        const response1 = await axios.get(buildAPIUrl('getcompanies')).then((res1)=>{
            console.log(res1.data)
            this.setState({
                companies: res1.data
            })
        }).catch((err)=>{

        })
        const response = await axios.get(buildAPIUrl(`get-salvage-details/${this.props.match.params.id}`)).then((res)=>{
            console.log(res)
            this.setState({
                make:res.data.make,
                model:res.data.model,
                reserve:res.data.reservePrice,
                companyReserve:res.data.companyReservePrice,
                company_id:res.data.company_id,
                category:res.data.category,
                regNo:res.data.regNo,
                location:res.data.location,
            })
        }).catch((err)=>{

        })

    }
    handleSalvageSave = async (e) => {
        e.preventDefault()
        const resp = await axios.put(buildAPIUrl(`update-salvage-details/${this.props.match.params.id}`), {
            regNo: this.state.regNo,
            make: this.state.make,
            model: this.state.model,
            reserve: this.state.reserve,
            companyreserve: this.state.companyReserve,
            company_id: this.state.company_id,
            location: this.state.location,
            category: this.state.category
        }        ).then((res)=>{
                window.location.assign('/admin-add-salvages')
        }).catch((err)=>{

        })
    }
    render() {
        console.log(this.state.category)
        return (
            <>
                <TopNav />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card ">
                                <div className="card-header">
                                    <h6 className="mt-3">Edit Salvages</h6>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.handleSalvageSave}>
                                        <div className="mb-3">
                                            <label>Registration Number</label>
                                            <input className="form-control" name="regNo" onChange={this.handleInput} value={this.state.regNo}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Make</label>
                                            <input className="form-control" name="make" onChange={this.handleInput} value={this.state.make}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Model</label>
                                            <input className="form-control" name="model" onChange={this.handleInput} value={this.state.model}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Reserve Price </label>
                                            <input className="form-control" name="reserve" onChange={this.handleInput} value={this.state.reserve} />
                                        </div>
                                        <div className="mb-3">
                                            <label>Company Reserve </label>
                                            <input className="form-control" name="companyReserve" onChange={this.handleInput} value={this.state.companyReserve} />
                                        </div>
                                        <div className="mb-3">
                                            <label>Location</label>
                                            <input className="form-control" name="location" onChange={this.handleInput} value={this.state.location}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Owner</label>
                                            <select className="form-control" name="company_id" onChange={this.handleInput} value={this.state.company_id}>
                                                <option>---Select Company---</option>
                                                {
                                                    this.state.companies ?

                                                        this.state.companies.map((company)=>(
                                                            <option defaultValue={company.id} value={company.id}>{company.first_name} {company.last_name}</option>
                                                        ))

                                                        : <option disabled>No Companies Loaded</option>
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label>Salvage Category</label>
                                            <select className="form-control" defaultValue={this.state.category} name="category" onChange={this.handleInput} value={this.state.category}>
                                                <option>---Select Salvage Type---</option>
                                                <option value="1" >Auction</option>
                                                <option value="2" >Listing</option>
                                                <option value="3" >Sold</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn btn-save">Save</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default EditSalvage