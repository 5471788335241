import React, {Component} from "react";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";
import UrlBuilder from '../../../Services/UrlBuilder'
import buildAPIUrl from "../../../Services/UrlBuilder";
import {Link} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import TopNavNew from "../components/TopNavNew";
import Header from "../components/Header";
class AddProperty extends Component{
    state={
        propertyName:"",
        price:"",
        nature:"",
        size:"",
        location:"",
        titleNumber:"",
        success:false,
        properties:"",
        loading:true
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    saveproperty = async (e)=>{
        e.preventDefault()
        const response =  await axios.post(buildAPIUrl('post-properties'), {
           propertyName:this.state.propertyName,
            price: this.state.price,
            location: this.state.location,
            size: this.state.size,
            nature:this.state.nature,
            titleNumber:this.state.titleNumber
        }).then((response)=>{
            this.setState({
                propertyName:"",
                price:"",
                nature:"",
                size:"",
                location:"",
                titleNumber:"",
                success:true
            })
            setInterval(() => {
               this.setState({
                   success:false
               })
            }, 3000);

        }).catch((e)=>{

        })
    }
    async componentDidMount (){
        const response =  await axios.get(buildAPIUrl('get-all-properties')).then((res)=>{
            console.log(res)
            this.setState({
                properties: res.data,
                loading:false
            })
        })
    }
    handleDeleteProperty= (id) => {
        const deletesalvage = axios.post(buildAPIUrl(`delete-property/${id}`)).then((res)=>{
            this.setState({
                delete:true
            })
            setTimeout(()=>{
                this.setState({
                    delete:false
                })
                window.location.reload()
            }, 3000)
        }).catch((error)=>{

        })
    }
    render() {
        console.log(this.state.mounted)
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="">Add Property</h6>
                                    <h6 className="text-success" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.success}</h6>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.saveproperty}>
                                        <div className={this.state.success? "alert alert-success" : "d-none"}>
                                            <h6>Property added successfully</h6>
                                        </div>
                                        <div className="mb-3">
                                            <label>Property Title</label>
                                            <input required className="form-control" name="propertyName" onChange={this.handleInput} value={this.state.propertyName}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Title Number</label>
                                            <input required className="form-control" type="text" name="titleNumber"  onChange={this.handleInput} value={this.state.titleNumber} />

                                        </div>
                                        <div className="mb-3">
                                            <label>Size</label>
                                            <input required type="text" className="form-control" name="size"  onChange={this.handleInput} value={this.state.size}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Price</label>
                                            <input required type="text" className="form-control" name="price"  onChange={this.handleInput} value={this.state.price}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Location</label>
                                            <input required type="text" className="form-control" name="location"  onChange={this.handleInput} value={this.state.location}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Nature of Development</label>
                                            <select required className="form-control" required name="nature"  onChange={this.handleInput} value={this.state.nature}>
                                                <option>-----Select Nature of Development----</option>
                                                <option value="Not Developed">Not Developed</option>
                                                <option value="Bungalow">Bungalow</option>
                                                <option value="Mansionette">Mansionette</option>
                                                <option value="Appartment">Appartment</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-save">Save</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className={this.state.delete ? "m-2 alert alert-success": "d-none"}>
                                    Deleting property...
                                </div>
                                <div className="card-header">
                                    <h6>Properties</h6>
                                </div>
                                <div className="card-body p-0 m-0">
                                    {
                                        this.state.loading ? <>
                                                <div className="container-fluid d-flex justify-content-center">
                                                    <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                                </div>
                                            </> :
                                            <table className="table table-nowrap table-centered align-middle">
                                                <tr className="options-table-header">
                                                    <th>Property Name</th>
                                                    <th>Title</th>
                                                    <th>Location</th>
                                                    <th>Size</th>
                                                    <th> Price</th>
                                                    <th> Nature</th>
                                                    <th>Options</th>
                                                </tr>
                                                {
                                                    this.state.properties ?
                                                        this.state.properties.map((property) => (
                                                                <tr>
                                                                    <td>{property.propertyName}</td>
                                                                    <td>{property.propertyTitle}</td>
                                                                    <td>{property.location}</td>
                                                                    <td>{property.size}</td>
                                                                    <td>{property.price}</td>
                                                                    <td>
                                                                        {property.nature}
                                                                    </td>
                                                                    <td>
                                                                        <Link to={`/upload-property-images/${property.id}`}>
                                                                            Add Images
                                                                        </Link>
                                                                        <br/>
                                                                        <Link to={`/edit-property/${property.id}`}>
                                                                            Edit Property
                                                                        </Link>
                                                                        <br/>
                                                                        <Link className={"mt-2 text-danger"} onClick={()=>this.handleDeleteProperty(property.id)}>
                                                                           Delete Property
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )

                                                        : <p>No Properties Found</p>
                                                }

                                            </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default AddProperty