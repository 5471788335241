import React, {Component} from "react";
import Dropzone from 'react-dropzone'
import TopNavNew from "../components/TopNavNew";
import Header from "../components/Header";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
class UploadPropertyImages extends Component{
    state = {
        images: [],
        success: false
    }
    handleChange = (e)=>{
        this.setState({
            images:e.target.files[0]
        })
    }
    acceptedFiles=(response) => {
        console.log(response)
        this.setState({
        })
    }
    uploadImages = async (res) => {
        res.preventDefault()
        const formdata = new FormData()
        formdata.append('image', this.state.images)
        formdata.append('property_id', this.props.match.params.id)
        const response = await axios({
            method: "post",
            url: buildAPIUrl('upload-property-images'),
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },

        }).then((resp)=>{
            console.log("Uploaded Successfully")
            console.log(resp)
            this.setState({
                success:true
            })
            setInterval(() => {
                this.setState({
                    success:false
                })
            }, 3000);
        })
    }
    render() {

        return (
            <>
                <TopNavNew />
                <Header />
                <div className="row m-5">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header"> Upload Images

                            </div>
                            <div className="card-body">
                                <form onSubmit={this.uploadImages} encType="multipart/form-data">
                                    <div className={this.state.success? "alert alert-success" : "d-none"}>
                                        <h6>Image uploaded successfully</h6>
                                    </div>
                                    <div className="form-group">
                                        <label>Attach File</label>
                                        <input type="file" multiple value={""} className="form-control" name="images" onChange={this.handleChange}/>
                                    </div>
                                    <div className="container mt-2  d-flex align-items-center justify-content-end">
                                        <button className="btn btn-secondary">Upload</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">

                    </div>
                </div>



            </>
        )
    }
}
export default UploadPropertyImages