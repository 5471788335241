import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import CountUp from "react-countup";

class Statistics extends Component{
    state = {
        salvages:0,
        cars:0,
        users:0,
        properties:0
    }
    componentDidMount() {
        const statistics = axios.get(buildAPIUrl('statistics')).then((res)=>{
            this.setState({
                salvages:res.data.salvages,
                cars:res.data.cars,
                users:res.data.users,
                properties:res.data.properties
            })
        }).catch((error)=>{

        })
    }

    render() {
        return (
            <div className="container mt-3 stats">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card p-4">
                            <h6>
                                Salvages
                            </h6>
                            <h3>
                                <CountUp end={this.state.salvages} duration={3} />
                            </h3>

                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card p-4">
                            <h6>
                                Cars
                            </h6>
                            <h3 >
                                <CountUp end={this.state.cars} duration={3} />
                            </h3>

                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card p-4">
                            <h6>
                                Properties
                            </h6>
                            <h3>
                                <CountUp end={this.state.properties} duration={3} />
                            </h3>

                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card p-4">
                            <h6>
                                Users
                            </h6>
                            <h3>
                                <CountUp end={this.state.users} duration={3} />
                            </h3>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Statistics