import React, {Component} from "react";
import Carimg from "../../../assets/car1.jpg"
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {Link} from "react-router-dom";
import {MdCarRental, MdCarRepair} from "react-icons/md";
import {FaCar, FaMapMarkerAlt} from "react-icons/fa";

class Cars extends Component{

    render() {
        return (
            <>
                {
                    this.props.type==="landing" ?  <div className="container-fluid pt-5 pb-5 cars-component" style={{padding:"2rem 0"}}>
                        <div className="container">
                            <h3 style={{ fontWeight:"600", fontSize:"40px"}}>Featured <span style={{color:"orangered"}}> Cars</span></h3>
                            <div className="row mt-3">
                                {
                                    this.props.cars.length > 0 ?

                                        this.props.cars.slice(0,6).map((car)=>(

                                                <div className="col-md-4 mt-5">
                                                    <Link to={`/car-details/${car.id}`} style={{textDecoration:"none"}}>
                                                    <div className="card bg-white">
                                                        <div className="car-img"  >
                                                            {
                                                                car.images.slice(0,1).map((image)=>(
                                                                    <img src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")} style={{width:"100%", height:"200px"}} />
                                                                ))
                                                            }

                                                        </div>
                                                        <div className="car-details px-3 pt-3">
                                                            <h5>{car.regNo}</h5>
                                                            <div className={"row p-0"}>
                                                                <div className={"col-md-4"}>
                                                                    <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}> <FaMapMarkerAlt /> {car.location} </h5>
                                                                </div>
                                                                <div className={"col-md-4"}>
                                                                    <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><MdCarRental /> {car.make}</h5>
                                                                </div>
                                                                <div className={"col-md-4"}>

                                                                    <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><MdCarRental /> {car.model}</h5>
                                                                </div>
                                                            </div>
                                                            <hr style={{height:"1px", border:"none", backgroundColor:"gray"}} />
                                                            <div className={"row mb-4"}>
                                                                <div className={"col-md-6 "}>
                                                                    <h6>KSh. {(+car.reservePrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>
                                                                </div>
                                                                <div className={"col-md-6  "}>
                                                                    <div className={"container-fluid d-flex align-items-center justify-content-center "}>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </Link>
                                                </div>
                                        ))
                                        : <>
                                            <p>There are no cars on sale</p>
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                        :
                        <div className="container-fluid pt-5 mb-5 cars-component bg-white" style={{padding:"2rem 0"}}>
                            <div className="container">
                                <h3 style={{fontWeight:"600", fontSize:"40px"}}>Featured  <span style={{color:"orangered"}}>Cars </span></h3>
                                <div className="row mt-3">
                                    {
                                        this.props.cars.length > 0 ?

                                            this.props.cars.map((car)=>(
                                                <div className="col-md-4 mt-5">
                                                    <Link to={`/car-details/${car.id}`} style={{textDecoration:"none"}}>
                                                        <div className="card bg-white">
                                                            <div className="car-img"  >
                                                                {
                                                                    car.images.slice(0,1).map((image)=>(
                                                                        <img src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")} style={{width:"100%", height:"200px"}} />
                                                                    ))
                                                                }

                                                            </div>
                                                            <div className="car-details px-3 pt-3">
                                                                <h5>{car.regNo}</h5>
                                                                <div className={"row p-0"}>
                                                                    <div className={"col-md-4"}>
                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}> <FaMapMarkerAlt /> {car.location} </h5>
                                                                    </div>
                                                                    <div className={"col-md-4"}>
                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><MdCarRental /> {car.make}</h5>
                                                                    </div>
                                                                    <div className={"col-md-4"}>

                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><MdCarRental /> {car.model}</h5>
                                                                    </div>
                                                                </div>
                                                                <hr style={{height:"1px", border:"none", backgroundColor:"gray"}} />
                                                                <div className={"row mb-4"}>
                                                                    <div className={"col-md-6 "}>
                                                                        <h6>KSh. {(+car.reservePrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>
                                                                    </div>
                                                                    <div className={"col-md-6  "}>
                                                                        <div className={"container-fluid d-flex align-items-center justify-content-center "}>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                            : <>
                                                <p>There are no cars on sale</p>
                                            </>
                                    }

                                </div>
                            </div>
                        </div>
                }
            </>

        )
    }
}
export default Cars