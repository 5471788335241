import React, {Component} from "react";
import TopNav from "./TopNav";
import Header from "./Header";

class Contact extends Component{
    render() {
        return(
            <>
                <TopNav />
                <Header />
                <div className="container-fluid">
                        <iframe className="mt-2"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8157567007065!2d36.82088186863854!3d-1.284476432423162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd08a9ed49e309fb5!2zMcKwMTcnMDYuMCJTIDM2wrA0OScxOS4zIkU!5e0!3m2!1sen!2ske!4v1650879914432!5m2!1sen!2ske"
                            height="450" style={{width:"100%"}} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">

                        </iframe>
                    <div className="container mt-5 contacts-section mb-5">
                        <div className="row">
                            <div className="col-md-5">
                                <h4>Contact Details</h4>
                                <h6 className="mt-2">
                                    Kenya --
                                </h6>
                                <h6>
                                    Nairobi, Wabera Street
                                </h6>
                                <h4 className="mt-4 mb-4">
                                    0791230249
                                </h4>
                                <h6 className="mt-2">
                                    info@reizen.auction
                                </h6>
                            </div>
                            <div className="col-md-7">
                                <h4>Contact Form</h4>
                                <form className="mt-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label>*First Name</label>
                                                <input type="text" className="form-control" name="firstname" placeholder="John"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label>*Last Name</label>
                                                <input className="form-control" type="text" name="lastname" placeholder="Doe"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label>*Email</label>
                                                <input type="email" className="form-control" name="email" placeholder="johndoe@email.com"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label>Phone Number</label>
                                                <input className="form-control" name="phone" placeholder="0700000000"/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label>*Message</label>
                                               <textarea className="form-control" name="message"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-outline-success">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default Contact