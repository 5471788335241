import React, {Component} from "react";
import {Link} from "react-router-dom"
import TopNav from "./TopNav";
import Header from "./Header";
import {HiOutlineHome} from "react-icons/hi"
import {FaCarCrash, FaCar} from "react-icons/fa"
import TopSection from "./components/TopSection";
import Salvages from "./components/Salvages";
import Cars from "./components/Cars";
import Property from "./components/Property";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import Properties from "./components/Properties";
import Footer from "./components/Footer";
import CountUp from "react-countup";
import MediaQuery from "react-responsive";

class Home extends Component{
    state = {
        salvages:[],
        properties:[],
        cars:[]
    }
    async componentDidMount() {
        let auctionid = this.props.match.params.id
        if(localStorage.getItem('expire')) {
            if (new Date() > localStorage.getItem('expire')) {
                localStorage.clear()
                window.location.assign('/')
            }
        }

       /* const response = await axios.get(buildAPIUrl(`landing-future-list`)).then((res)=>{
            this.setState({
                salvages: res.data.salvages
            })
        })*/
        const response1 = await axios.get(buildAPIUrl(`get-all-properties`)).then((res1)=>{
            console.log(res1, "properties")
            this.setState({
                properties: res1.data
            })
        }).catch((error)=>{

        })
        const response = axios.get(buildAPIUrl('get-all-cars')).then((res)=>{
            console.log(res, "cars")
            this.setState({
                cars:res.data
            })
        }).catch((error)=>{

        })
        const response2 =  axios.get(buildAPIUrl('salvages-on-sale')).then((res)=>{
            this.setState({
                salvages: res.data,
            })
        })
        setInterval(()=>{
            this.setState({
                loading:false
            })
        }, 3000)
    }
    render() {
        return (
            <>
                <TopNav />
                <Header />
                <TopSection />
                <MediaQuery minWidth={1200}>
                    <div id={"about-us"} className={"container about-us"} >
                        <div className={"row"}>
                            <div className={"col-md-7 d-flex align-items-center"}>
                                <div className={"container"}>
                                    <h5 style={{fontSize:"40px", lineHeight:"38px", fontWeight:"900", color:"rgb(227, 75, 29)"}}> <span style={{color:"#070707", fontWeight:"600"}}>Welcome to </span>  Reizen Auction</h5>
                                    <p className={"mt-3"} style={{maxWidth:"80%", fontSize:"18px", lineHeight:"28px"}}>
                                        Reizen Auction is a reputable auctioneering firm giving access to salvages, cars and Properties. Licensed to undertake auctioneer business under the <span style={{color:"rgb(227, 75, 29)"}}> Auctioneers Act No.5 of 1996 </span> of the laws of Kenya.
                                    </p>
                                    <p style={{maxWidth:"80%", fontSize:"18px", lineHeight:"28px"}}>
                                        The license enables us to repossess, sell movable and immovable property in Kiambu, Kajiado and Nairobi counties.
                                    </p>
                                    <h6 style={{fontSize:"22px", lineHeight:"38px", fontWeight:"600"}} >WHAT WE DO</h6>
                                    <ul  style={{maxWidth:"80%", fontSize:"18px", lineHeight:"28px"}}>
                                        <li>
                                            Repossessions of motor vehicles or any other items in pursuant to the provision of any written law or contract.
                                        </li>
                                        <li>
                                            Sale of movable and immovable property by auction.
                                        </li>
                                        <li>
                                            Levy of distress for rent on behalf of the landlords.
                                        </li>
                                        <li>
                                            Debt collection
                                        </li>
                                        <li>
                                            Execution of court warrants
                                        </li>
                                    </ul>

                                </div>

                            </div>
                            <div className={"col-md-5"}>
                                <div className={"container stats-counter px-5"}>
                                    <div className={"row"}>
                                        <div className={"col-md-6 border-end pt-3 pb-3"}>
                                            <h6>Salvages auctioned</h6>
                                            <h4><CountUp end={1222} duration={10} /></h4>

                                        </div>
                                        <div className={"col-md-6 pt-3 pb-3"}>
                                            <h6>Properties sold</h6>
                                            <h4><CountUp end={218} duration={10} /></h4>

                                        </div>
                                        <div className={"col-md-6 border-end border-top  pt-3 pb-3"}>
                                            <h6>Cars sold</h6>
                                            <h4><CountUp end={134} duration={10} /></h4>
                                        </div>
                                        <div className={"col-md-6  border-top  pt-3 pb-3"}>

                                            <h6>Customers served</h6>
                                            <h4><CountUp end={3400} duration={10} /></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <Salvages salvages={this.state.salvages} />*/}
                    <div className={"container-fluid p-0  mb-5"}>
                        {this.state.cars.length === 0 ?    "" : <Cars cars={this.state.cars} type={"landing"} /> }
                        {
                            this.state.salvages.length===0? "" :  <Salvages salvages={this.state.salvages} type={"listings"} />
                        }
                        {
                            this.state.properties.length=== 0 ? "" :  <Properties type={"landing"} properties={this.state.properties} />
                        }

                    </div>

                    <div id={"terms"} className="container-fluid pt-4 pb-4" style={{backgroundColor:"#f5f5f6"}}>
                        <div  className="container">
                            <h2 className="text-center mt-5" style={{fontSize:"40px", fontWeight:"600", color:"#222732"}}>Auction <span style={{color:"orangered"}} >Terms </span></h2>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <h4 className={"mb-3"} style={{fontSize:"25px", fontWeight:"500", color:"#222732"}}>With deposit</h4>
                                        <ol className={"terms-list"}>
                                            <li>Viewing can be done during normal working hours at the various locations indicated to verify the details</li>
                                            <li> All interested buyers are requested to view and verify the details by themselves as REIZEN AUCTION does not warrant these, as the units are being offered for sale "AS THEY ARE".</li>
                                            <li> Reizen Auction will list vehicles on www.reizen.auction one week before the auction date to allow prospective buyers to assess the condition of the listed vehicles</li>
                                            <li> Successful bidders will have to pay kes 50,000 per vehicle as the participation fee. The balance shall be payable in 2 working days following the auction but vehicles shall be released upon clearance of the balance. Please note that failure to pay the balance will result in forfeiture of all deposits paid.</li>
                                            <li>  Sale will be subject to reasonable reserve prices and the full amount for all vehicles bided shall be paid before release of any other vehicles.</li>
                                            <li>  Bidders who successfully bid for more than one vehicle will have to pay the full purchase price for all vehicles bided for before any of them is released.</li>
                                            <li> Any award of a vehicle to the highest bidder is subject to approval by the Auction Committee. Reizen Auction reserves the right to, at any time, in its sole and absolute discretion and with or without notice, rescind, postpone, or cancel a sale or withdraw an awarded vehicle at any time before buyer receives the assigned title.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <h4 className={"mb-3"} style={{fontSize:"25px", fontWeight:"500", color:"#222732"}}>With participation fees</h4>
                                        <ol className={"terms-list"}>
                                            <li>Visit www.reizen.auction</li>
                                            <li>View vehicles online</li>
                                            <li>Register your details on the website</li>
                                            <li>Plan a visit to inspect the condition of the vehicles</li>
                                            <li>On the auction date, pay the auction fee of KES 1,000 and place your bid.</li>
                                            <li>Monitor the current bids and if someone out bids your you can place new bids without paying any more auction fees</li>
                                            <li>The winning bidder shall be informed via email, text and finally a call from our customer services</li>
                                            <li>The email received shall contain instructions of paying at least KES 50,000 within 48 hours of tender closing in the insurance company account.</li>
                                            <li>The balance has to be paid within 5 working days of the closing of the tender, failure to which the bidder loses the deposit</li>
                                            <li>In a case where the winning bidder does not pay, Reizen Auction shall recommend the second bidder to be awarded the tender at the discretion of the insurance company availing the salvage</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"mobile"}>
                        <div id={"about-us"} className={"container about-us"} >
                            <div className={"row"}>
                                <div className={"col-md-7 d-flex align-items-center"}>
                                    <div className={"container"}>
                                        <h5 style={{fontSize:"30px", lineHeight:"38px", fontWeight:"900", color:"rgb(227, 75, 29)"}}> <span style={{color:"#070707", fontWeight:"600"}}>Welcome to </span>  <br/> Reizen Auction</h5>
                                        <p className={"mt-3"} style={{maxWidth:"100%", fontSize:"18px", lineHeight:"28px"}}>
                                            Reizen Auction is a reputable auctioneering firm giving access to salvages, cars and Properties. Licensed to undertake auctioneer business under the <span style={{color:"rgb(227, 75, 29)"}}> Auctioneers Act No.5 of 1996 </span> of the laws of Kenya.
                                        </p>
                                        <p style={{maxWidth:"100%", fontSize:"18px", lineHeight:"28px"}}>
                                            The license enables us to repossess, sell movable and immovable property in Kiambu, Kajiado and Nairobi counties.
                                        </p>
                                        <h6 style={{fontSize:"22px", lineHeight:"38px", fontWeight:"600"}} >WHAT WE DO</h6>
                                        <ul  style={{maxWidth:"100%", fontSize:"18px", lineHeight:"28px"}}>
                                            <li>
                                                Repossessions of motor vehicles or any other items in pursuant to the provision of any written law or contract.
                                            </li>
                                            <li>
                                                Sale of movable and immovable property by auction.
                                            </li>
                                            <li>
                                                Levy of distress for rent on behalf of the landlords.
                                            </li>
                                            <li>
                                                Debt collection
                                            </li>
                                            <li>
                                                Execution of court warrants
                                            </li>
                                        </ul>

                                    </div>

                                </div>
                                <div className={"col-md-5"} style={{background:"inherit"}}>
                                    <div className={"container stats-counter px-2"} style={{background:"inherit"}}>
                                        <div className={"row"}>
                                            <div className={"col-md-6 col-6 border-end  pt-3 pb-3"}>
                                                <h6>Salvages auctioned</h6>
                                                <h4><CountUp end={1222} duration={10} /></h4>

                                            </div>
                                            <div className={"col-md-6 col-6 pt-3 pb-3"}>
                                                <h6>Properties sold</h6>
                                                <h4><CountUp end={218} duration={10} /></h4>

                                            </div>
                                            <div className={"col-md-6 col-6  border-top border-end  pt-3 pb-3"}>
                                                <h6>Cars sold</h6>
                                                <h4><CountUp end={134} duration={10} /></h4>
                                            </div>
                                            <div className={"col-md-6 col-6  border-top pt-3 pb-3"}>

                                                <h6>Customers served</h6>
                                                <h4><CountUp end={3400} duration={10} /></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <Salvages salvages={this.state.salvages} />*/}
                        <div className={"container-fluid p-0  mb-5"}>
                            {this.state.cars.length === 0 ?    "" : <Cars cars={this.state.cars} type={"landing"} /> }
                            {
                                this.state.salvages.length===0? "" :  <Salvages salvages={this.state.salvages} type={"listings"} />
                            }
                            {
                                this.state.properties.length=== 0 ? "" :  <Properties type={"landing"} properties={this.state.properties} />
                            }

                        </div>

                        <div id={"terms"} className="container-fluid pt-4 pb-4" style={{backgroundColor:"#f5f5f6"}}>
                            <div  className="container">
                                <h2 className="text-center mt-5" style={{fontSize:"40px", fontWeight:"600", color:"#222732"}}>Auction Terms</h2>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card-body">
                                            <h4 className={"mb-3"} style={{fontSize:"25px", fontWeight:"500", color:"#222732"}}>With deposit</h4>
                                            <ol className={"terms-list"}>
                                                <li>Viewing can be done during normal working hours at the various locations indicated to verify the details</li>
                                                <li> All interested buyers are requested to view and verify the details by themselves as REIZEN AUCTION does not warrant these, as the units are being offered for sale "AS THEY ARE".</li>
                                                <li> Reizen Auction will list vehicles on www.reizen.auction one week before the auction date to allow prospective buyers to assess the condition of the listed vehicles</li>
                                                <li> Successful bidders will have to pay kes 50,000 per vehicle as the participation fee. The balance shall be payable in 2 working days following the auction but vehicles shall be released upon clearance of the balance. Please note that failure to pay the balance will result in forfeiture of all deposits paid.</li>
                                                <li>  Sale will be subject to reasonable reserve prices and the full amount for all vehicles bided shall be paid before release of any other vehicles.</li>
                                                <li>  Bidders who successfully bid for more than one vehicle will have to pay the full purchase price for all vehicles bided for before any of them is released.</li>
                                                <li> Any award of a vehicle to the highest bidder is subject to approval by the Auction Committee. Reizen Auction reserves the right to, at any time, in its sole and absolute discretion and with or without notice, rescind, postpone, or cancel a sale or withdraw an awarded vehicle at any time before buyer receives the assigned title.</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card-body">
                                            <h4 className={"mb-3"} style={{fontSize:"25px", fontWeight:"500", color:"#222732"}}>With participation fees</h4>
                                            <ol className={"terms-list"}>
                                                <li>Visit www.reizen.auction</li>
                                                <li>View vehicles online</li>
                                                <li>Register your details on the website</li>
                                                <li>Plan a visit to inspect the condition of the vehicles</li>
                                                <li>On the auction date, pay the auction fee of KES 1,000 and place your bid.</li>
                                                <li>Monitor the current bids and if someone out bids your you can place new bids without paying any more auction fees</li>
                                                <li>The winning bidder shall be informed via email, text and finally a call from our customer services</li>
                                                <li>The email received shall contain instructions of paying at least KES 50,000 within 48 hours of tender closing in the insurance company account.</li>
                                                <li>The balance has to be paid within 5 working days of the closing of the tender, failure to which the bidder loses the deposit</li>
                                                <li>In a case where the winning bidder does not pay, Reizen Auction shall recommend the second bidder to be awarded the tender at the discretion of the insurance company availing the salvage</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div id={"about-us"} className={"container about-us"} >
                        <div className={"row"}>
                            <div className={"col-md-7 d-flex align-items-center"}>
                                <div className={"container"}>
                                    <h5 style={{fontSize:"40px", lineHeight:"38px", fontWeight:"900", color:"rgb(227, 75, 29)"}}> <span style={{color:"#070707", fontWeight:"600"}}>Welcome to </span>  Reizen Auction</h5>
                                    <p className={"mt-3"} style={{maxWidth:"80%", fontSize:"18px", lineHeight:"28px"}}>
                                        Reizen Auction is a reputable auctioneering firm giving access to salvages, cars and Properties. Licensed to undertake auctioneer business under the <span style={{color:"rgb(227, 75, 29)"}}> Auctioneers Act No.5 of 1996 </span> of the laws of Kenya.
                                    </p>
                                    <p style={{maxWidth:"80%", fontSize:"18px", lineHeight:"28px"}}>
                                        The license enables us to repossess, sell movable and immovable property in Kiambu, Kajiado and Nairobi counties.
                                    </p>
                                    <h6 style={{fontSize:"22px", lineHeight:"38px", fontWeight:"600"}} >WHAT WE DO</h6>
                                    <ul  style={{maxWidth:"80%", fontSize:"18px", lineHeight:"28px"}}>
                                        <li>
                                            Repossessions of motor vehicles or any other items in pursuant to the provision of any written law or contract.
                                        </li>
                                        <li>
                                            Sale of movable and immovable property by auction.
                                        </li>
                                        <li>
                                            Levy of distress for rent on behalf of the landlords.
                                        </li>
                                        <li>
                                            Debt collection
                                        </li>
                                        <li>
                                            Execution of court warrants
                                        </li>
                                    </ul>

                                </div>

                            </div>
                            <div className={"col-md-5"}>
                                <div className={"container stats-counter px-5"}>
                                    <div className={"row"}>
                                        <div className={"col-md-6 border-end pt-3 pb-3"}>
                                            <h6>Salvages auctioned</h6>
                                            <h4><CountUp end={1222} duration={10} /></h4>

                                        </div>
                                        <div className={"col-md-6 border-top pt-3 pb-3"}>
                                            <h6>Properties sold</h6>
                                            <h4><CountUp end={218} duration={10} /></h4>

                                        </div>
                                        <div className={"col-md-6 border-top  pt-3 pb-3"}>
                                            <h6>Cars sold</h6>
                                            <h4><CountUp end={134} duration={10} /></h4>
                                        </div>
                                        <div className={"col-md-6  border-top  pt-3 pb-3"}>

                                            <h6>Customers served</h6>
                                            <h4><CountUp end={3400} duration={10} /></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <Salvages salvages={this.state.salvages} />*/}
                    <div className={"container-fluid p-0  mb-5"}>
                        {this.state.cars.length === 0 ?    "" : <Cars cars={this.state.cars} type={"landing"} /> }
                        {
                            this.state.salvages.length===0? "" :  <Salvages salvages={this.state.salvages} type={"listings"} />
                        }
                        {
                            this.state.properties.length=== 0 ? "" :  <Properties type={"landing"} properties={this.state.properties} />
                        }

                    </div>

                    <div id={"terms"} className="container-fluid pt-4 pb-4" style={{backgroundColor:"#f5f5f6"}}>
                        <div  className="container">
                            <h2 className="text-center mt-5" style={{fontSize:"35px", fontWeight:"600", color:"rgb(23, 70, 162)"}}>Auction Terms</h2>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <h4 className={"mb-3"} style={{fontSize:"25px", fontWeight:"500", color:"rgb(23, 70, 162)"}}>With deposit</h4>
                                        <ol className={"terms-list"}>
                                            <li>Viewing can be done during normal working hours at the various locations indicated to verify the details</li>
                                            <li> All interested buyers are requested to view and verify the details by themselves as REIZEN AUCTION does not warrant these, as the units are being offered for sale "AS THEY ARE".</li>
                                            <li> Reizen Auction will list vehicles on www.reizen.auction one week before the auction date to allow prospective buyers to assess the condition of the listed vehicles</li>
                                            <li> Successful bidders will have to pay kes 50,000 per vehicle as the participation fee. The balance shall be payable in 2 working days following the auction but vehicles shall be released upon clearance of the balance. Please note that failure to pay the balance will result in forfeiture of all deposits paid.</li>
                                            <li>  Sale will be subject to reasonable reserve prices and the full amount for all vehicles bided shall be paid before release of any other vehicles.</li>
                                            <li>  Bidders who successfully bid for more than one vehicle will have to pay the full purchase price for all vehicles bided for before any of them is released.</li>
                                            <li> Any award of a vehicle to the highest bidder is subject to approval by the Auction Committee. Reizen Auction reserves the right to, at any time, in its sole and absolute discretion and with or without notice, rescind, postpone, or cancel a sale or withdraw an awarded vehicle at any time before buyer receives the assigned title.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <h4 className={"mb-3"} style={{fontSize:"25px", fontWeight:"500", color:"rgb(23, 70, 162)"}}>With participation fees</h4>
                                        <ol className={"terms-list"}>
                                            <li>Visit www.reizen.auction</li>
                                            <li>View vehicles online</li>
                                            <li>Register your details on the website</li>
                                            <li>Plan a visit to inspect the condition of the vehicles</li>
                                            <li>On the auction date, pay the auction fee of KES 1,000 and place your bid.</li>
                                            <li>Monitor the current bids and if someone out bids your you can place new bids without paying any more auction fees</li>
                                            <li>The winning bidder shall be informed via email, text and finally a call from our customer services</li>
                                            <li>The email received shall contain instructions of paying at least KES 50,000 within 48 hours of tender closing in the insurance company account.</li>
                                            <li>The balance has to be paid within 5 working days of the closing of the tender, failure to which the bidder loses the deposit</li>
                                            <li>In a case where the winning bidder does not pay, Reizen Auction shall recommend the second bidder to be awarded the tender at the discretion of the insurance company availing the salvage</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>

                <Footer />


            </>
                )
    }
}
export default Home