import React, {Component} from "react";
import TopNav from "../TopNav";
import Header from "../Header";
import Carimg from "../../../assets/car1.jpg"
import {FaWhatsapp} from "react-icons/fa"
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Carousel from 'react-bootstrap/Carousel';
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";

class SalvageDetails extends Component{
    state = {
        salvage_id: this.props.match.params.id,
        auction_id: localStorage.getItem('auction_id'),
        salvage: "",
        images:[],
        bidAmount:"",
        highestBid:"",
        bids:[],
        reload: false,
        loading: true
    }

    async componentDidMount(){
        const response = axios.get(buildAPIUrl(`salvage-details/${this.state.salvage_id}`)).then((res)=>{
            console.log(res)
            this.setState({
                salvage: res.data,
                images:res.data.images,
                loading:false
            })

        })

    }
    render() {
        console.log(this.state.reload)
        return (
            <>
                <TopNav />
                <Header />
                {
                    this.state.loading ? <>
                            <div className="container-fluid d-flex justify-content-center">
                                <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                            </div>
                        </> :
                        <div className="container-fluid mt-5 item-details">
                            <div className="container">
                                <div className="row top-details">
                                    <div className="col-md-6">
                                        <h3>{this.state.salvage.make}, {this.state.salvage.model}</h3>

                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end">
                                        {this.state.salvage.companyReservePrice > 0 ? <h6 className="px-2">Reserve Price</h6> : " " }
                                        <h3>{this.state.salvage.companyReservePrice > 0 ? (+this.state.salvage.companyReservePrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ""}</h3>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6 details-img">
                                        <div className="container-fluid">
                                            <Carousel>
                                                {
                                                    this.state.images.map((image, key) => (
                                                        <Carousel.Item>
                                                            <img
                                                                className="d-block w-100"
                                                                src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}
                                                                alt="First slide"
                                                            />

                                                        </Carousel.Item>
                                                        /* <div className={key === 0 ? 'active carousel-item' : 'carousel-item' }>
                                                             <img className="d-block w-100" src= alt="First slide" />
                                                         </div>*/
                                                    ))
                                                }

                                            </Carousel>
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5">
                                        <div className="container-fluid">
                                            <div className="container bg-white pt-5 pb-5 mb-5">
                                                <h6>Overview</h6>
                                                <table className="table table-hover table-borderless mt-3">
                                                    <tr>
                                                        <td className="overview-title">Registration No.</td>
                                                        <td className="overview-details">{this.state.salvage.regNo}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="overview-title">Make</td>
                                                        <td className="overview-details">{this.state.salvage.make}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="overview-title">Model</td>
                                                        <td className="overview-details">{this.state.salvage.model}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="overview-title">Location</td>
                                                        <td className="overview-details">{this.state.salvage.location}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        {/*  <div className="card bg-white pt-4">
                                    <div className="container-fluid text-center company-heading">
                                        <h5>Reizen Auction</h5>
                                        <h6>0791230249 </h6>
                                    </div>

                                    <h5 className="mt-3">Send Offer</h5>
                                    <form>
                                        <div className="mb-3">
                                            <input type="text" name="name" className="form-control" placeholder="Name"/>
                                        </div>
                                        <div className="mb-3">
                                            <input type="text" name="name" className="form-control" placeholder="Phone"/>
                                        </div>
                                        <div className="mb-3">
                                            <input type="text" name="name" className="form-control" placeholder="Offer"/>
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn btn-outline-dark">Place Offer</button>
                                            <button className="btn btn-success"> <FaWhatsapp/> Send Whatsapp Message</button>
                                        </div>
                                    </form>
                                </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </>

        )
    }
}
export default SalvageDetails