import React, {Component} from "react";
import Logo from "../../assets/reizen.png"
import {Link} from "react-router-dom";
import {
    MdOutlineHome,
    MdOutlineEventNote,
    MdEventAvailable,
    MdOutlineHouse,
    MdLogin,
    MdOutlineAppRegistration,
    MdCarRepair, MdCarRental, MdLogout
} from "react-icons/md";
import MediaQuery from "react-responsive";
class Header extends Component{
    state = {
        currentpage:window.location.pathname
    }
    handleLogout = (e)=>{
        localStorage.clear()
        window.location.assign('/login')
    }
    render() {
        return(
            <>
                <MediaQuery minWidth={1200}>
                    <div className="container-fluid border-bottom bg-light">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                                <a className="navbar-brand" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mr-auto">
                                        <li className={this.state.currentpage==="/" ? "nav-item active" :"nav-item"}>
                                            <Link className="nav-link" to="/"> <MdOutlineHome /> Home</Link>
                                        </li>
                                        <li className= {this.state.currentpage==="/future-auctions" ||  this.state.currentpage==="/current-auction" ? "nav-item active dropdown" :"nav-item dropdown"}>
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Auctions
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="nav-link" to="/future-auctions"> <MdOutlineEventNote /> Future Auctions</Link>
                                                <Link className="nav-link" to="/current-auction"> <MdEventAvailable /> Current Auction</Link>
                                            </div>
                                        </li>
                                        <li className={this.state.currentpage==="/#about-us" ||  this.state.currentpage==="/#terms" ? "nav-item active dropdown" :"nav-item dropdown"}>
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Company
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <a className="nav-link" href="#about-us"> <MdOutlineEventNote /> About us</a>
                                                <a className="nav-link" href="#terms"> <MdEventAvailable /> Terms</a>

                                            </div>
                                        </li>

                                        <li className={this.state.currentpage==="/salvage-listings" ? "nav-item active" :"nav-item"}>
                                            <Link className="nav-link" to="/salvage-listings"> <MdCarRepair /> Salvages on sale</Link>
                                        </li>
                                        <li className={this.state.currentpage==="/cars" ? "nav-item active" :"nav-item"}>
                                            <Link className="nav-link" to="/cars"> <MdCarRental /> Cars on sale</Link>
                                        </li>

                                        <li className={this.state.currentpage==="/properties" ? "nav-item active" :"nav-item"}>
                                            <Link className="nav-link" to="/properties"> <MdOutlineHouse /> Properties</Link>
                                        </li>
                                        {/*<li className="nav-item">
                                    <a className="nav-link" href="#">Property</a>
                                </li>*/}
                                        {/*
                               */}
                                    </ul>


                                </div>
                                {
                                    localStorage.getItem('email') ? " " :
                                        <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarSupportedContent">
                                            <ul className="navbar-nav mr-auto">
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="/login"> <MdLogin /> Login</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="/register"> <MdOutlineAppRegistration /> Register</Link>
                                                </li>
                                            </ul>

                                        </div>




                                }

                                {
                                    localStorage.getItem('email') ?
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul className="navbar-nav mr-auto d-flex justify-content-end">
                                                <li className="nav-item">
                                                    <a className="nav-link" style={{cursor:"pointer"}} onClick={this.handleLogout}> <MdLogout/>Logout</a>
                                                </li>
                                            </ul>
                                        </div> : " "
                                }
                            </nav>
                        </div>

                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className="container-fluid border-bottom bg-light">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mr-auto">
                                        <li className="nav-item active">
                                            <Link className="nav-link" to="/"> <MdOutlineHome /> Home</Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Auctions
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="nav-link" to="/future-auctions"> <MdOutlineEventNote /> Future Auctions</Link>
                                                <Link className="nav-link" to="/current-auction"> <MdEventAvailable /> Current Auction</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Company
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <a className="nav-link" href="#about-us"> <MdOutlineEventNote /> About us</a>
                                                <a className="nav-link" href="#terms"> <MdEventAvailable /> Terms</a>

                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <Link className="nav-link" to="/salvage-listings"> <MdCarRepair /> Salvages on sale</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/cars"> <MdCarRental /> Cars on sale</Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link className="nav-link" to="/properties"> <MdOutlineHouse /> Properties</Link>
                                        </li>
                                        {
                                            localStorage.getItem('email') ? " " :
                                                <>
                                                    <li className="nav-item" style={{display: "inline"}}>
                                                        <Link className="nav-link" to="/login"> <MdLogin/> Login</Link>
                                                    </li>
                                                    <li className="nav-item" style={{display:"inline"}}>
                                                        <Link className="nav-link" to="/register"> <MdOutlineAppRegistration /> Register</Link>
                                                    </li>
                                                </>

                                        }
                                        {/*<li className="nav-item">
                                    <a className="nav-link" href="#">Property</a>
                                </li>*/}
                                        {/*
                               */}
                                    </ul>


                                </div>


                                {
                                    localStorage.getItem('email') ?
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul className="navbar-nav mr-auto d-flex justify-content-end">
                                                <li className="nav-item">
                                                    <a className="nav-link" style={{cursor:"pointer"}} onClick={this.handleLogout}> <MdLogout/>Logout</a>
                                                </li>
                                            </ul>
                                        </div> : " "
                                }
                            </nav>
                        </div>

                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className="container-fluid border-bottom bg-light">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                                <a className="navbar-brand" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mr-auto">
                                        <li className="nav-item active">
                                            <Link className="nav-link" to="/"> <MdOutlineHome /> Home</Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Auctions
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="nav-link" to="/future-auctions"> <MdOutlineEventNote /> Future Auctions</Link>
                                                <Link className="nav-link" to="/current-auction"> <MdEventAvailable /> Current Auction</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Company
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <a className="nav-link" href="#about-us"> <MdOutlineEventNote /> About us</a>
                                                <a className="nav-link" href="#terms"> <MdEventAvailable /> Terms</a>

                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <Link className="nav-link" to="/salvage-listings"> <MdCarRepair /> Salvages on sale</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/cars"> <MdCarRental /> Cars on sale</Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link className="nav-link" to="/properties"> <MdOutlineHouse /> Properties</Link>
                                        </li>
                                        {/*<li className="nav-item">
                                    <a className="nav-link" href="#">Property</a>
                                </li>*/}
                                        {/*
                               */}
                                    </ul>


                                </div>
                                {
                                    localStorage.getItem('email') ? " " :
                                        <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarSupportedContent">
                                            <ul className="navbar-nav mr-auto">
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="/login"> <MdLogin /> Login</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="/register"> <MdOutlineAppRegistration /> Register</Link>
                                                </li>
                                            </ul>

                                        </div>




                                }

                                {
                                    localStorage.getItem('email') ?
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul className="navbar-nav mr-auto d-flex justify-content-end">
                                                <li className="nav-item">
                                                    <a className="nav-link" style={{cursor:"pointer"}} onClick={this.handleLogout}> <MdLogout/>Logout</a>
                                                </li>
                                            </ul>
                                        </div> : " "
                                }
                            </nav>
                        </div>

                    </div>
                </MediaQuery>
            </>

        )
    }
}
export default Header