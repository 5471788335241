import React, {Component} from "react";
import Logo from "../../../assets/reizen.png"
import {Link} from "react-router-dom";
import TopNav from "../TopNav";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import swal from "sweetalert";

class Forgot extends Component{
    state={
        email:""
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleResetPassword = async (e) =>{
        e.preventDefault()
        const response = await axios.post(buildAPIUrl('send-reset-link'), {
            email:this.state.email
        }).then((res)=>{
            swal({
                title: "Successful",
                text: res.data,
                icon: "success",
                button: "Close",
            }).then((res)=>{
                window.location.assign("/login")
            })
        }).catch((error)=>{

        })
    }
    render() {
        return (
            <>
                <TopNav />
                <div className="container authentication">
                    <div className="row mt-5 d-flex justify-content-center">
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-header">
                                    <h4 style={{color:"#1a3760"}}>Reset Password</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.handleResetPassword}>
                                        <div className="mb-3">
                                            <p>Enter Your Email to Reset Password</p>
                                            <input className="form-control" name="email" type="email" onChange={this.handleInput} value={this.state.email}/>
                                        </div>
                                        <div className="mb-3">
                                            <p> <Link to="/login" style={{color:"#13c56b", fontWeight:"500", textDecoration:"none"}} > Already have a profile? Sign in. </Link></p>
                                            <button className="btn" style={{background:"#13c56b", color:"white", width:"100%"}}>Reset password</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default Forgot