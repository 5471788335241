import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {Link} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";

class Auctions extends Component{
    state={
        change: new Date(),
        startdate: new Date(),
        auctionTitle:"",
        auctionDate:"",
        auctionStartTime:"",
        auctionEndTime:"",
        success: "",
        auctions:"",
        loading:true
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async componentDidMount (){
        const response =  await axios.get(buildAPIUrl(`get-company-auctions/${localStorage.getItem('item')}`)).then((res)=>{
            console.log(res)
            this.setState({
                auctions: res.data,
                loading:false
            })
        })
    }
    render() {
        return (
            <div className="container mt-5 auctions">
                <div className="row">

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h6>Auctions</h6>
                            </div>
                            <div className="card-body p-0 m-0">
                                {
                                    this.state.loading ? <>
                                            <div className="container-fluid d-flex justify-content-center">
                                                <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                            </div>
                                        </> :
                                        <table className="table table-nowrap table-centered align-middle">
                                            <tr className="options-table-header">
                                                <th>Auction Title</th>
                                                <th>Date</th>
                                                <th>Start Time</th>
                                                <th>End time</th>
                                                <th>Options</th>
                                            </tr>
                                            {
                                                this.state.auctions ?
                                                    this.state.auctions.map((auction) => (
                                                            <tr>
                                                                <td>{auction.auctionTitle}</td>
                                                                <td>{auction.auctionDate}</td>
                                                                <td>{auction.auctionStartTime}</td>
                                                                <td>{auction.auctionEndTime}</td>
                                                                <td>
                                                                    <Link to={`/company-auction-details/${auction.id}`}>
                                                                        View Auction
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )

                                                    : <p>No Auctions Found</p>
                                            }

                                        </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Auctions