import React, {Component} from "react";
import Logo from "../../../assets/reizenwhite.png";
import {FaRegBell, FaRegUserCircle} from "react-icons/fa"
class TopNavNew extends Component {
    render() {
        return (
            <div className="container-fluid pt-2 pb-2 top-nav">
             <div className="container">
                 <div className="row">
                     <div className="col-md-6">
                         <img className="logo" src={Logo}/>
                     </div>
                     <div className="col-md-6 d-flex justify-content-end align-items-center">
                         <span>
                        {localStorage.getItem('first_name') + " " + localStorage.getItem('last_name')}
                      </span>
                     </div>
                 </div>
             </div>
            </div>
        )
    }
}
export default TopNavNew