import React, {Component} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import {Link} from "react-router-dom";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import TopNavNew from "./components/TopNavNew";
import Header from "./components/Header";
import Statistics from "./components/Statistics";

class EditAuction extends Component{
    state= {
        success:false,
        auctionTitle:"",
        auctionDate:"",
        auctionStartTime:"",
        auctionEndTime:"",
        auctionEndTimePrev:"",
        auctionType:"",
        bonus:false,
        salvages:[],
        auctionid: this.props.match.params.id,
        deleted:false
    }
    componentDidMount() {
        const response = axios.get(buildAPIUrl(`get-auction-details/${this.props.match.params.id}`)).then((res)=>{
           this.setState({
               auctionTitle:res.data.auctionTitle,
               auctionDate:new Date(),
               auctionStartTime:res.data.auctionStartTime,
               auctionEndTime:res.data.auctionEndTime,
               auctionType:res.data.auctionType,
               auctionEndTimePrev:res.data.auctionEndTime,
           })
            console.log(res)
        })
        // Get the salvages in that auction
        const response1 = axios.get(buildAPIUrl(`get-salvages-in-auction/${this.state.auctionid}`)).then((res1)=>{
            console.log("items",res1)
            this.setState({
                salvages: res1.data.salvages,
            })

        })
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    updateauction = async (e)=>{
        e.preventDefault()
        const response =  await axios.put(buildAPIUrl(`update-auction/${this.props.match.params.id}`), {
            auctionTitle:this.state.auctionTitle,
            auctionDate: this.state.auctionDate,
            auctionStartTime: this.state.auctionStartTime,
            auctionEndTime: this.state.auctionEndTime,
            auctionType:this.state.auctionType,
            bonus:this.state.bonus,
            starttime:this.state.auctionEndTimePrev
        }).then((response)=>{
            this.setState({
                change: new Date(),
                startdate: new Date(),
                auctionTitle:"",
                auctionDate:"",
                auctionStartTime:"",
                auctionEndTime:"",
                success: true
            })
            setInterval(()=>{
                window.location.assign('/admin-dashboard')
            }, 3000)
        }).catch((e)=>{

        })
    }
    handleCheckbox = (e)=>{
        console.log(e.target.checked)
        let isChecked = e.target.checked
        if(isChecked){
            this.setState({
                bonus:true
            })
        } else if (!isChecked){
            this.setState({
                bonus:false
            })
        }
    }
    deleteAuction = (id) =>{
        //Delete salvage from auction
        const auctionid = this.props.match.params.id
        const response = axios.post(buildAPIUrl(`delete-salvage-from-an-auction/${id}/${auctionid}`)).then((res)=>{
            this.setState({
                deleted:true
            })
            setTimeout(()=>{
                this.setState({
                    deleted:false
                })
                window.location.reload()
            }, 3000)
        }).catch((error)=>{
            console.log(error)
        })
    }

    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <Statistics />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="">Update Auction</h6>
                                    {
                                        this.state.success ? <h6 className="alert alert-success">Auction updated successfully</h6> :""
                                    }
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.updateauction}>
                                        <div className="mb-3">
                                            <label>Auction Title</label>
                                            <input className="form-control" name="auctionTitle" onChange={this.handleInput} value={this.state.auctionTitle}/>
                                        </div>
                                        {/*<div className="mb-3">
                                            <label>Auction Date </label>
                                            <input className="form-control" type="date" name="auctionDate"  onChange={this.handleInput} value={this.state.auctionDate} />

                                        </div>*/}
                                        <div className="mb-3">
                                            <label>Auction Start Time</label>
                                            <input type="datetime-local" className="form-control" name="auctionStartTime"  onChange={this.handleInput} value={this.state.auctionStartTime}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Auction End Time</label>
                                            <input type="datetime-local" className="form-control" name="auctionEndTime"  onChange={this.handleInput} value={this.state.auctionEndTime}/>
                                            <input type="datetime-local" hidden={true} className="form-control" name="auctionEndTime"  value={this.state.auctionEndTimePrev}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Auction Type</label>
                                            <select  className="form-control" required name="auctionType"  onChange={this.handleInput} value={this.state.auctionType}>
                                                <option value="">---Select Auction Type---</option>
                                                <option value="1">Salvages</option>
                                                <option value="2">Properties</option>
                                            </select>
                                        </div>
                                        <div className={"mb-3"}>
                                            <input className="form-check-input" type="checkbox"
                                                   id="flexCheckDefault" onChange={e=>this.handleCheckbox(e)} />
                                                <label style={{marginLeft:"10px"}}  className="form-check-label" htmlFor="flexCheckDefault">
                                                    Bonus time
                                                </label>
                                        </div>
                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-save">Save</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className={"col-md-8"}>
                            <div className={"card"}>
                                <div className={this.state.deleted ? "alert alert-danger mx-3 mt-2" : "d-none"}>Deleting salvage...</div>
                                <div className={"card-header"}>Salvages in this auction</div>
                                <div className={"card-body"}>
                                    <table  className="table table-borderless" id="salvages">
                                        <thead>
                                        <tr className="options-table-header">
                                            <th>Reg Number</th>
                                            <th>Make</th>
                                            <th>Model</th>
                                            <th>Delete</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.salvages ? <>

                                                {
                                                    this.state.salvages.map((salavge)=>(
                                                        <tr>
                                                            <td>{salavge.regNo}</td>
                                                            <td>{salavge.make}</td>
                                                            <td>{salavge.model}</td>
                                                            <td><h6 className={"text-danger"} style={{cursor:"pointer"}} onClick={()=>this.deleteAuction(salavge.id)}>Remove</h6> </td>

                                                        </tr>
                                                    ))
                                                }



                                            </> : <p>There is no active auction running</p>
                                        }


                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }

}
export default EditAuction