import React, {Component} from "react";
import TopNavNew from "../components/TopNavNew";
import Header from "../components/Header";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
class AddSalvageToAuction extends Component{
    state = {
        success: "",
        salvages:[],
        salvage_id:"",
        salvageDetails:"",
        salvagesInAuction:[]
    }
    handleInput = (e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async componentDidMount (){
       await axios.get(buildAPIUrl('get-salvages')).then((res)=>{
            console.log(res)
            this.setState({
                salvages:res.data
            })
        });
       await axios.get(buildAPIUrl(`get-auction-details/${this.props.match.params.id}`)).then((res1)=>{
            this.setState({
                salvageDetails:res1.data
            })
        })
        await axios.get(buildAPIUrl(`get-salvages-in-auction/${this.props.match.params.id}`)).then((res2)=>{
            this.setState({
                salvagesInAuction:res2.data.salvages
            })
        })
    }
    addSalvagetoAuction = async (e) =>{
        e.preventDefault()
        const response = await axios.post(buildAPIUrl('add-salvage-to-auction'), {
            auction_id:this.props.match.params.id,
            salvage_id:this.state.salvage_id,
        }).then((res)=>{
            console.log(res)
            this.setState({
                success:res.data
            })
           axios.get(buildAPIUrl(`get-salvages-in-auction/${this.props.match.params.id}`)).then((res2)=>{
                this.setState({
                    salvagesInAuction:res2.data.salvages
                })
            })
        }).catch((err)=>{
            console.log(err)
        })

    }
    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="row m-5">
                    <div className="col-md-4">
                        <div className="card border">
                            <div className="card-header"> Add Salvage to Auction

                            </div>
                            <div className="card-body">
                                {
                                    this.state.success ?  <h6 className="alert alert-success" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.success}</h6>
                                        : ""
                                }

                                <form onSubmit={this.addSalvagetoAuction} encType="multipart/form-data">
                                    <div className="form-group">
                                        <label>Select Salvage</label>
                                     <select className="form-control" onChange={this.handleInput} value={this.state.salvage_id} name="salvage_id">
                                         <option>---Select Salvage---</option>
                                         {
                                             this.state.salvages.map((salvage)=>(
                                                 <option value={salvage.id}>{salvage.regNo}</option>
                                             ))
                                         }
                                     </select>
                                    </div>
                                        <button className="btn btn-save mt-2">Upload</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">

                        <div className="container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h6>Title: <span>{this.state.salvageDetails.auctionTitle}</span> </h6>
                                        </div>
                                        <div className="col-md-6">
                                            <h6>Date:  <span>{this.state.salvageDetails.auctionDate}</span></h6>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <h6>
                                                Start Time:  <span>{this.state.salvageDetails.auctionStartTime}</span>
                                            </h6>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <h6>
                                                End Time:  <span>{this.state.salvageDetails.auctionEndTime}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="container mt-5">
                            <div className="card">
                                <div className="card-header">
                                    <h6>Salvages in this Auction</h6>
                                </div>
                                <div className="card-body">
                                    <table className="table table-nowrap table-centered align-middle">
                                        <tr className="options-table-header">
                                            <th>Make</th>
                                            <th>Model</th>
                                            <th>Reserve Price</th>
                                            <th>Location</th>

                                        </tr>
                                        {
                                            this.state.salvagesInAuction ? <>
                                                {
                                                    this.state.salvagesInAuction.map((salvage)=>(
                                                        <tr>
                                                            <td>{salvage.make}</td>
                                                            <td>{salvage.model}</td>
                                                            <td>{salvage.reservePrice}</td>
                                                            <td>{salvage.location}</td>
                                                        </tr>

                                                    ))
                                                }
                                            </> : <>
                                                <p>There are no salvages in this auction</p>
                                            </>
                                        }
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default AddSalvageToAuction