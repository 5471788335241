import React, {Component} from "react";
import TopNav from "./TopNav";
import Header from "../../components/Customer/components/Header";
import Salvages from "./components/Salvages";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import TopNavNew from "../Customer/components/TopNavNew";

class UserCurrentAuction extends Component{
    state = {
        salvages:"",
        loading:true
    }
componentDidMount() {
        const response =  axios.get(buildAPIUrl('current-auction')).then((res)=>{
            this.setState({
                salvages: res.data.salvages,
                loading:false
            })
            localStorage.setItem('auction_id', res.data.auction_id)
            localStorage.setItem('auctionendtime', res.data.auctionendtime)
            localStorage.setItem('auctioncategory', res.data.auctionCategory)
        })
    }
    render() {
        return (
            <div>
                <TopNavNew />
                <Header />

                <Salvages salvages={this.state.salvages} type={"current"}/>
            </div>
        );
    }
}
export default UserCurrentAuction