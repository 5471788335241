import React, {Component} from "react";
import TopNavNew from "./components/TopNavNew";
import Header from "./components/Header";
import Statistics from "./components/Statistics";
import Auctions from "./components/Auctions";

class Dashboard extends Component{
    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <Auctions />
            </>
        )
    }
}

export default Dashboard