import React, {Component} from "react";
import TopNavNew from "./components/TopNavNew";
import Header from "./components/Header";
import Statistics from "./components/Statistics";
import Auctions from "./components/Auctions";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import Salvages from "../Landing/components/Salvages";
import Properties from "../Landing/components/Properties";
import {MdUpload, MdUploadFile} from "react-icons/all";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import {Link} from "react-router-dom";

class Dashboard extends Component{
    state = {
        salvages:[],
        auctionType:null,
        auctionCategory:null,
        loading:false,
        auctions:[]
    }
    async componentDidMount() {
        //Current auction
        const response = await axios.get(buildAPIUrl('current-auction')).then((res)=>{
            console.log("Current auction", res.data)
            this.setState({
                salvages: res.data.salvages,
                auctionType:res.data.auction_details.auctionType,
                auctionCategory:res.data.auction_details.auctionCategory
            })
            localStorage.setItem('auction_id', res.data.auction_id)
            localStorage.setItem('auctionendtime', res.data.auctionendtime)
            localStorage.setItem('auctionCategory', res.data.auctionCategory)
        })
        //Future auction
        const response1 =  axios.get(buildAPIUrl('future-auctions')).then((res)=>{
            this.setState({
                auctions: res.data,
                loading:false
            })
        })
    }
    handleUploadNewItem = ()=>{

    }
    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <Statistics />
                {
                    this.state.salvages.length > 0 ?
                        <div className={"container mt-4 mb-4"}>
                            <Salvages salvages={this.state.salvages} type={"current"}/>
                        </div> : " "
                }

              {/*  <div className={"container mt-4 mb-4"}>
                    <div className={"alert alert-warning border border-warning"}>
                        You can now upload salvages, cars and properties on our platform for free. Offer is valid until 31st Dec 2022.
                    </div>
                </div>*/}
                <div className="container mt-3 auctions">
                    <div className="row">
                        <div className="col-md-8">
                          {/*  <div className="card ">

                                <form onSubmit={this.handleSalvageSave}>
                                    <div className="card-body">
                                        <h5 className="mt-3 mb-3">Upload Item</h5>
                                        <div className={this.state.success ? "alert alert-success" : "d-none"}>Salvage Added Successfully</div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>Registration Number</label>
                                                    <input className="form-control" name="regNo" onChange={this.handleInput} value={this.state.regNo}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label>Make</label>
                                                    <input className="form-control" name="make" onChange={this.handleInput} value={this.state.make}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label>Model</label>
                                                    <input className="form-control" name="model" onChange={this.handleInput} value={this.state.model}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>Price </label>
                                                    <input className="form-control" name="reserve" onChange={this.handleInput} value={this.state.reserve} />
                                                </div>
                                                <div className="mb-3">
                                                    <label>Location</label>
                                                    <input className="form-control" name="location" onChange={this.handleInput} value={this.state.location}/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className={"card-footer d-flex justify-content-end"}>
                                        <button className={"btn btn-save"}>
                                            Next
                                        </button>
                                    </div>
                                </form>

                            </div>*/}
                        </div>
                        <div className={"col-md-4"}>
                            <div className="card">
                                <div className="card-body px-3 m-0">
                                    <h5 className={"mt-4 mb-4"}>Future Auctions</h5>
                                    {
                                        this.state.auctions.length > 0 ?
                                            this.state.auctions.map((auction) => (
                                                <div className={"container-fluid p-2 px-3 border mb-5"}>
                                                    <h6>{auction.auctionTitle}</h6>
                                                    <div className={"row mt-3"}>
                                                        <div className={"col-md-6"}>
                                                            <h6 style={{fontSize:"12px", fontWeight:"400"}}>From:</h6>
                                                            <h6>{auction.auctionStartTime}</h6>
                                                        </div>
                                                        <div className={"col-md-6"}>
                                                            <h6 style={{fontSize:"12px", fontWeight:"400"}}>To:</h6>
                                                            <h6>{auction.auctionEndTime}</h6>
                                                        </div>
                                                    </div>


                                                </div>
                                            ))
                                            :  "There are no future auctions at the moment"
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default Dashboard