import React, {Component} from "react";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";
import UrlBuilder from '../../../Services/UrlBuilder'
import TopNavNew from "./TopNavNew";
import Header from "./Header";
import Statistics from "./Statistics";
import buildAPIUrl from "../../../Services/UrlBuilder";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import swal from "sweetalert";
class AddCompany extends Component{
    state={
        companyName:"",
        success:false,
        companies:"",
        loading:true
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    saveCompany = async (e)=>{
        e.preventDefault()
        document.getElementById('submit-button').innerHTML = 'Submitting...'
        const response =  await axios.post(buildAPIUrl('savecompany'), {
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            email:this.state.email,
            phone:this.state.phone,
        }).then((response)=>{
            this.setState({
                firstname:"",
                lastname:"",
                email:"",
                phone:"",
                success:true,

            })
            setInterval(()=>{
                this.setState({
                    success:false
                })
            }, 3000)
            document.getElementById('submit-button').innerHTML = 'Save'
        }).catch((e)=>{
            swal({
                title: "Opps!",
                text: e.response.data.message,
                icon: "error",
                button: "Close",
            })
            console.log(e)
        })
    }
    componentDidMount() {
        const response = axios.get(buildAPIUrl('getcompanies')).then((res)=>{
            console.log(res.data)
            this.setState({
                companies: res.data,
                loading:false
            })
        }).catch((err)=>{

        })
    }

    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="">Add Company</h6>

                                </div>
                                <div className="card-body">
                                    <h6 className={this.state.success ? "alert alert-success" : "d-none" } style={{fontSize:"12px", fontWeight:"400"}}>Company saved successfully</h6>
                                    <form onSubmit={this.saveCompany}>
                                        <div className="mb-3">
                                            <label> First Name</label>
                                            <input required className="form-control" name="firstname" onChange={this.handleInput} value={this.state.firstname}/>
                                        </div>
                                        <div className="mb-3">
                                            <label> Last Name</label>
                                            <input required className="form-control" name="lastname" onChange={this.handleInput} value={this.state.lastname}/>
                                        </div>
                                        <div className="mb-3">
                                            <label> Phone</label>
                                            <input required type="number" className="form-control" name="phone" onChange={this.handleInput} value={this.state.phone}/>
                                        </div>
                                        <div className="mb-3">
                                            <label> Email</label>
                                            <input type="email" required className="form-control" name="email" onChange={this.handleInput} value={this.state.email}/>
                                        </div>
                                        <div className="mb-3">
                                            <button type="submit" id="submit-button" className="btn btn-save">Save</button>
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <h6>Companies</h6>
                                </div>
                                <div className="card-body p-0 m-0">
                                    {
                                        this.state.loading ? <>
                                                <div className="container-fluid d-flex justify-content-center">
                                                    <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                                </div>
                                            </> :
                                            <table className="table table-nowrap">
                                                <tr className="">
                                                    <th>Company Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Status</th>
                                                </tr>
                                                {
                                                    this.state.companies ?
                                                        this.state.companies.map((company) => (

                                                            <tr>
                                                                <td>{company.first_name + " " + company.last_name}</td>
                                                                <td>{company.email}</td>
                                                                <td>{company.phone}</td>

                                                                {
                                                                    company.status === 0 ?
                                                                        <td className="text-success">Active</td> :
                                                                        <td className="text-warning">Suspended</td>
                                                                }
                                                            </tr>
                                                        ))
                                                        : <>
                                                            <h6>No data found</h6>
                                                        </>
                                                }

                                            </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default AddCompany