import React, {Component} from "react";
import TopNav from "../components/TopNavNew";
import Header from "../components/Header";

class Salvages extends Component{
    render() {
        return (
            <>
                <TopNav />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="mt-3">Salvages</h6>
                                </div>
                                <div className="card-body p-0 m-0">
                                    <table className="table table-hover table-striped table-borderless ">
                                        <tr className="options-table-header">
                                            <th>Reg Number</th>
                                            <th>Make</th>
                                            <th>Model</th>
                                            <th>Location</th>
                                            <th>Add</th>
                                            <th>Options</th>
                                        </tr>
                                        <tr>
                                            <td>KCA 12345</td>
                                            <td>Toyota</td>
                                            <td>Rush</td>
                                            <td>Meru</td>
                                            <td>
                                                Add Images
                                            </td>
                                            <td>
                                                Edit
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>KCA 12345</td>
                                            <td>Toyota</td>
                                            <td>Rush</td>
                                            <td>Meru</td>
                                            <td>
                                                Add Images
                                            </td>
                                            <td>

                                                Edit
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default Salvages