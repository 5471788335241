import React, {Component} from "react";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";
import UrlBuilder from '../../../Services/UrlBuilder'
import buildAPIUrl from "../../../Services/UrlBuilder";
import {Link} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import TopNavNew from "../components/TopNavNew";
import Header from "../components/Header";
class EditProperty extends Component{
    state={
        propertyName:"",
        price:"",
        nature:"",
        size:"",
        location:"",
        titleNumber:"",
        success:false,
        properties:"",
        loading:true,
        propertyCategory:""
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    saveproperty = async (e)=>{
        e.preventDefault()
        const response =  await axios.put(buildAPIUrl(`edit-properties/${this.props.match.params.id}`), {
            propertyName:this.state.propertyName,
            price: this.state.price,
            location: this.state.location,
            size: this.state.size,
            nature:this.state.nature,
            titleNumber:this.state.titleNumber,
            propertyCategory:this.state.propertyCategory
        }).then((response)=>{
            this.setState({
                propertyName:"",
                price:"",
                nature:"",
                size:"",
                location:"",
                titleNumber:"",
                success:true
            })
            setInterval(() => {
                this.setState({
                    success:false
                })
                window.location.assign('/add-property')
            }, 3000);

        }).catch((e)=>{

        })
    }
    async componentDidMount (){
        const response =  await axios.get(buildAPIUrl(`property-details/${this.props.match.params.id}`)).then((res)=>{
            console.log(res)
            this.setState({
                propertyName:res.data.propertyName,
                price: res.data.price,
                location: res.data.location,
                size: res.data.size,
                nature:res.data.nature,
                titleNumber:res.data.propertyTitle,
                propertyCategory:res.data.propertyCategory,
                loading:false
            })
        })
    }
    render() {
        console.log(this.state.propertyCategory)
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="">Update Property</h6>
                                    <h6 className="text-success" style={{fontSize:"12px", fontWeight:"400"}}>{this.state.success}</h6>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.saveproperty}>
                                        <div className={this.state.success? "alert alert-success" : "d-none"}>
                                            <h6>Property updated successfully</h6>
                                        </div>
                                        <div className="mb-3">
                                            <label>Property Title</label>
                                            <input required className="form-control" name="propertyName" onChange={this.handleInput} value={this.state.propertyName}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Title Number</label>
                                            <input required className="form-control" type="text" name="titleNumber"  onChange={this.handleInput} value={this.state.titleNumber} />

                                        </div>
                                        <div className="mb-3">
                                            <label>Size</label>
                                            <input required type="text" className="form-control" name="size"  onChange={this.handleInput} value={this.state.size}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Price</label>
                                            <input required type="text" className="form-control" name="price"  onChange={this.handleInput} value={this.state.price}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Location</label>
                                            <input required type="text" className="form-control" name="location"  onChange={this.handleInput} value={this.state.location}/>
                                        </div>
                                        <div className="mb-3">
                                            <label>Nature of Development</label>
                                            <select className="form-control" required name="nature"  onChange={this.handleInput} value={this.state.nature}>
                                                <option>-----Select Nature of Development----</option>
                                                <option value="Not Developed">Not Developed</option>
                                                <option value="Bungalow">Bungalow</option>
                                                <option value="Mansionette">Mansionette</option>
                                                <option value="Appartment">Appartment</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label>Property Category</label>
                                            <select required className="form-control" name="propertyCategory"  onChange={this.handleInput} value={this.state.propertyCategory}>
                                                <option>-----Select Nature of Development----</option>
                                                <option value="0">On Sale</option>
                                                <option value="1">Sold</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-save">Update</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </>

        )
    }
}
export default EditProperty