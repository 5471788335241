import React, {Component} from "react";
import Carimg from "../../../assets/car1.jpg"
import {Link} from "react-router-dom";
import {FaHome, FaList, FaMapMarkerAlt, FaRulerCombined} from "react-icons/fa";
import {MdHouse} from "react-icons/md";


class Properties extends Component{
    handleNavigation = (id) => {
        window.location.assign(`/property-details/${id}`)
    }
    render() {
        return (
            <div className="container-fluid cars-component mb-5 pb-5 mt-3" style={{backgroundColor:"white"}}>
                <div className="container">
                    <h3 style={{fontWeight:"600", fontSize:"40px"}}>Featured  <span style={{color:"orangered"}}>Properties </span></h3>
                    { this.props.type==="landing" ?
                        <div className="row">
                            {
                                this.props.properties ?

                                    this.props.properties.slice(0,4).map((property)=>(
                                        <div className="col-md-4 mt-5">
                                            {
                                                this.props.type === "current" ?
                                                    <Link
                                                        to={`/property-place-bid/${property.id}`} style={{textDecoration:"none"}}>
                                                        <div className="card">
                                                            <div className="car-img">
                                                                {
                                                                    property.propertyimages.slice(0, 1).map((image) => (
                                                                        <img
                                                                            src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}
                                                                            style={{
                                                                                width: "100%",
                                                                            }}/>
                                                                    ))
                                                                }

                                                            </div>
                                                            <div className="car-details px-3 pt-3">
                                                                <h5>{property.propertyName}</h5>
                                                                <div className={"row p-0"}>
                                                                    <div className={"col-md-4"}>
                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}> <FaMapMarkerAlt /> {property.location} </h5>
                                                                    </div>
                                                                    <div className={"col-md-4"}>
                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><MdHouse /> {property.nature}</h5>
                                                                    </div>
                                                                    <div className={"col-md-4"}>

                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><FaRulerCombined /> {property.size}</h5>
                                                                    </div>
                                                                </div>
                                                                <hr style={{height:"1px", border:"none", backgroundColor:"gray"}} />
                                                                <div className={"row mb-4"}>
                                                                    <div className={"col-md-6 "}>
                                                                        <h6>KSh. {(+property.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>
                                                                    </div>
                                                                    <div className={"col-md-6  "}>
                                                                        <div className={"container-fluid d-flex align-items-center justify-content-center "}>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                    :
                                                    <Link
                                                        to={`/property-details/${property.id}`} style={{textDecoration:"none"}}>
                                                    <div className="card">
                                                        <div className="car-img">
                                                            {
                                                                property.propertyimages.slice(0, 1).map((image) => (
                                                                    <img
                                                                        src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}
                                                                        style={{
                                                                            width: "100%",

                                                                        }}/>
                                                                ))
                                                            }

                                                        </div>
                                                        <div className="car-details px-3 pt-3">
                                                            <h5>{property.propertyName}</h5>
                                                            <div className={"row p-0"}>
                                                                <div className={"col-md-4"}>
                                                                    <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}> <FaMapMarkerAlt /> {property.location} </h5>
                                                                </div>
                                                                <div className={"col-md-4"}>
                                                                    <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><MdHouse /> {property.nature}</h5>
                                                                </div>
                                                                <div className={"col-md-4"}>

                                                                    <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><FaRulerCombined /> {property.size}</h5>
                                                                </div>
                                                            </div>
                                                            <hr style={{height:"1px", border:"none", backgroundColor:"gray"}} />
                                                            <div className={"row mb-4"}>
                                                                <div className={"col-md-6 "}>
                                                                    <h6>KSh. {(+property.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>
                                                                </div>
                                                                <div className={"col-md-6  "}>
                                                                    <div className={"container-fluid d-flex align-items-center justify-content-center "}>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    </Link>
                                            }
                                        </div>
                                    ))
                                    : <>
                                        <p>Currently we do not have any properties on sale</p>
                                    </>
                            }

                        </div> :
                        <div className="row">
                            {
                                this.props.properties ?

                                    this.props.properties.map((property)=>(
                                        <div className="col-md-4 mt-3">
                                            {
                                                this.props.type === "current" ?
                                                    <Link
                                                        to={`/property-place-bid/${property.id}`} style={{textDecoration:"none"}}>
                                                        <div className="card">
                                                            <div className="car-img">
                                                                {
                                                                    property.propertyimages.slice(0, 1).map((image) => (
                                                                        <img
                                                                            src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}
                                                                            style={{
                                                                                width: "100%",
                                                                            }}/>
                                                                    ))
                                                                }

                                                            </div>
                                                            <div className="car-details px-3 pt-3">
                                                                <h5>{property.propertyName}</h5>
                                                                <div className={"row p-0"}>
                                                                    <div className={"col-md-4"}>
                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}> <FaMapMarkerAlt /> {property.location} </h5>
                                                                    </div>
                                                                    <div className={"col-md-4"}>
                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><MdHouse /> {property.nature}</h5>
                                                                    </div>
                                                                    <div className={"col-md-4"}>

                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><FaRulerCombined /> {property.size}</h5>
                                                                    </div>
                                                                </div>
                                                                <hr style={{height:"1px", border:"none", backgroundColor:"gray"}} />
                                                                <div className={"row mb-4"}>
                                                                    <div className={"col-md-6 "}>
                                                                        <h6>KSh. {(+property.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>
                                                                    </div>
                                                                    <div className={"col-md-6  "}>
                                                                        <div className={"container-fluid d-flex align-items-center justify-content-center "}>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                    :
                                                    <Link
                                                        to={`/property-details/${property.id}`} style={{textDecoration:"none"}}>
                                                        <div className="card">
                                                            <div className="car-img">
                                                                {
                                                                    property.propertyimages.slice(0, 1).map((image) => (
                                                                        <img
                                                                            src={window.origin + "/backend/" + image.filelocation.replace("public", "storage")}
                                                                            style={{
                                                                                width: "100%",
                                                                            }}/>
                                                                    ))
                                                                }

                                                            </div>
                                                            <div className="car-details px-3 pt-3">
                                                                <h5>{property.propertyName}</h5>
                                                                <div className={"row p-0"}>
                                                                    <div className={"col-md-4"}>
                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}> <FaMapMarkerAlt /> {property.location} </h5>
                                                                    </div>
                                                                    <div className={"col-md-4"}>
                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><MdHouse /> {property.nature}</h5>
                                                                    </div>
                                                                    <div className={"col-md-4"}>

                                                                        <h5 style={{fontSize:"14px", marginTop:"10px", fontWeight:"400"}}><FaRulerCombined /> {property.size}</h5>
                                                                    </div>
                                                                </div>
                                                                <hr style={{height:"1px", border:"none", backgroundColor:"gray"}} />
                                                                <div className={"row mb-4"}>
                                                                    <div className={"col-md-6 "}>
                                                                        <h6>KSh. {(+property.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>
                                                                    </div>
                                                                    <div className={"col-md-6  "}>
                                                                        <div className={"container-fluid d-flex align-items-center justify-content-center "}>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Link>
                                            }
                                        </div>
                                    ))
                                    : <>
                                        <p>Currently we do not have any properties on sale</p>
                                    </>
                            }

                        </div>
                    }

                </div>
            </div>
        )
    }
}
export default Properties