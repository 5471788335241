import React, {Component} from "react";
import Header from "./Header";
import {MdCheck, MdStar} from "react-icons/all";
import TopNav1 from "../../Landing/TopNav";
import Header1 from "../../Landing/Header";
import TopNav from "./TopNavNew";

class AuctionEnded extends Component{
    handleLogout = () =>{
        localStorage.clear()
        window.location.assign('/login')
    }
    render() {
        return (
            <>
                <TopNav />
                <Header />
                <div className={"row mt-5 d-flex justify-content-center"}>
                    <div className={"col-md-5 bg-white text-center"} style={{borderRadius:"12px", boxShadow:"-3px 6px 21px -18px rgba(0,0,0,0.85)"}}>
                        <div className={"container d-flex justify-content-center"}>
                             <span className={"bg-success d-flex justify-content-center align-items-center text-center"} style={{marginTop:"10%", borderRadius:"50%", width:"60px", height:"60px"}} >
                             <MdCheck style={{color:"white", fontSize:"50px"}} />
                        </span>
                        </div>

                        <h4 className={"mt-4"}>Thank you for participating in today's auction</h4>
                       {/* <h5 className={"mt-3 fw-light"}>You were the highest bidder in 2 items</h5>*/}
                        <h5 className={"mt-3 fw-light"}>We will contact you with more details</h5>
                        <button onClick={this.handleLogout} className={"mt-3 col-md-6 btn btn-success"} style={{ marginBottom:"10%", fontSize:"20px"}}>
                            Logout
                        </button>
                    </div>
                </div>
            </>
        );
    }
}
export default AuctionEnded