import React, {Component} from "react";
import TopNavNew from "./TopNavNew";
import Header from "./Header";
import {Link} from "react-router-dom";
import {FaRegEdit} from "react-icons/fa";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {MdKeyboardArrowRight} from "react-icons/all";

class UploadItem extends Component{
    state = {
        companies:"",
        make:"",
        model:"",
        reserve:"",
        companyReserve:"",
        company_id:"",
        category:"",
        regNo:"",
        location:"",
        salvages:"",
        success:false
    }
    handleInput = (e)=> {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    async componentDidMount() {
        const response = await axios.get(buildAPIUrl('getcompanies')).then((res)=>{
            this.setState({
                companies: res.data
            })
        }).catch((err)=>{

        })
        const respo = await axios.get(buildAPIUrl('get-salvages')).then((resp)=>{
            console.log(resp)
            this.setState({
                salvages: resp.data
            })
        }).catch((err)=>{

        })
    }
    handleSalvageSave = async (e) => {
        e.preventDefault()
        const resp = await axios.post(buildAPIUrl('add-salvages'), {
            regNo: this.state.regNo,
            make: this.state.make,
            model: this.state.model,
            reserve: this.state.reserve,
            companyreserve: this.state.companyReserve,
            company_id: this.state.company_id,
            location: this.state.location,
            category: this.state.category
        }).then((res)=>{
            this.setState({
                regNo: "",
                make: "",
                model: "",
                reserve: "",
                companyreserve: "",
                company_id: "",
                location: "",
                category: "",
                success: true
            })
            setInterval(() => {
                this.setState({
                    success:false
                })
            }, 3000);
        }).catch((err)=>{

        })
    }
    render() {
        return (
            <>
                <TopNavNew />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="card ">
                                <div className="card-header" style={{background:"#5F9DF7"}}>
                                    <h6 className="mt-3">Upload Item</h6>
                                    <br/>

                                </div>
                                <form onSubmit={this.handleSalvageSave}>
                                <div className="card-body">
                                    <div className={this.state.success ? "alert alert-success" : "d-none"}>Salvage Added Successfully</div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>Registration Number</label>
                                                    <input className="form-control" name="regNo" onChange={this.handleInput} value={this.state.regNo}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label>Make</label>
                                                    <input className="form-control" name="make" onChange={this.handleInput} value={this.state.make}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label>Model</label>
                                                    <input className="form-control" name="model" onChange={this.handleInput} value={this.state.model}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label>Price </label>
                                                    <input className="form-control" name="reserve" onChange={this.handleInput} value={this.state.reserve} />
                                                </div>
                                                <div className="mb-3">
                                                    <label>Location</label>
                                                    <input className="form-control" name="location" onChange={this.handleInput} value={this.state.location}/>
                                                </div>
                                            </div>

                                        </div>
                                </div>
                                    <div className={"card-footer d-flex justify-content-end"}>
                                        <button className={"btn btn-save"}>
                                            Next
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default UploadItem