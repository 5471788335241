import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import TopNav from "./TopNavNew";
import Header from "./Header";
import Printer, { print } from 'react-pdf-print';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import ClipLoader from "react-spinners/ClipLoader";
import {ScaleLoader} from "react-spinners";
import ReactToPrint from "react-to-print";
class CompanyAuctionDetails extends Component{
    state = {
        active:"",
        salvages:[],
        bids:"",
        auctionid: this.props.match.params,
        loading:true
    }
    componentDidMount(){
        const response1 = axios.get(buildAPIUrl(`get-salvages-in-auction/${this.state.auctionid.id}`)).then((res1)=>{
            console.log(res1)
            this.setState({
                salvages: res1.data.salvages,
                loading:false
            })
        })
    }
    handlePrint = (e) => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#salvages', body:[[{content: 'Reizen Auction Report', colSpan: 2, rowSpan: 2, styles: { halign: 'center' } }]], styles:{cellWidth: 45}, includeHiddenHtml:false})
        doc.save(new Date().toLocaleDateString())
    }
    render() {

        const ids = ['1']
        return(
            <>
                <TopNav />
                <Header />
                <div className="container mt-5 auctions">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-6">  <h6 className="mt-3">Auction</h6></div>
                                            <div className="col-md-6 d-flex justify-content-end">
                                                <ReactToPrint
                                                    trigger={() => {
                                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                        // to the root node of the returned component as it will be overwritten.
                                                        return <button className="btn btn-success">Print Report</button>;
                                                    }}
                                                    content={() => this.componentRef}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-body p-0 m-0">

                                    <div className="container">

                                        {
                                            this.state.loading ? <>
                                                    <div className="container-fluid d-flex justify-content-center">
                                                        <ClipLoader color={"red"} loading={ScaleLoader} size={100}/>
                                                    </div>
                                                </> :
                                                <table className="table table-borderless" ref={el => (this.componentRef = el)}>

                                                    <thead>
                                                    <tr className="options-table-header">
                                                        <th style={{width: "30%"}}>Reg Number</th>
                                                        <th>Make</th>
                                                        <th>Model</th>
                                                        <th>Bids</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.salvages ? <>

                                                            {
                                                                this.state.salvages.map((salavge) => (
                                                                    <tr>
                                                                        <td>{salavge.regNo}</td>
                                                                        <td>{salavge.make}</td>
                                                                        <td>{salavge.model}</td>
                                                                        <td>{
                                                                            salavge.bids ? <ol>
                                                                                {
                                                                                    salavge.bids.map((bid) => (
                                                                                        <>

                                                                                            <li style={{
                                                                                                paddingTop: "2%",
                                                                                                width: "100%"
                                                                                            }}><span
                                                                                                className="text-black"
                                                                                                style={{
                                                                                                    fontWeight: "700",
                                                                                                    marginRight: "10px"
                                                                                                }}>{bid.name}</span><span
                                                                                                className="badge text-primary">{bid.bidAmount}</span>
                                                                                                <i style={{fontSize: "12px"}}>{new Date(bid.created_at).toLocaleDateString()} {new Date(bid.created_at).toLocaleTimeString()}</i>
                                                                                            </li>
                                                                                        </>


                                                                                    ))
                                                                                }
                                                                            </ol> : <p>There are no bids</p>
                                                                        }</td>
                                                                    </tr>
                                                                ))
                                                            }


                                                        </> : <p>There is no active auction running</p>
                                                    }
                                                    <div className="" id="content">
                                                        <h6>Reizen Auction Report</h6>
                                                        <table className="d-none" id="salvages">

                                                            <thead>
                                                            <tr className="options-table-header">
                                                                <th style={{width: "30%"}}>Reg Number</th>
                                                                <th>Make</th>
                                                                <th>Model</th>
                                                                <th>Bids</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                this.state.salvages ? <>

                                                                    {
                                                                        this.state.salvages.map((salavge) => (
                                                                            <tr>
                                                                                <td>{salavge.regNo}</td>
                                                                                <td>{salavge.make}</td>
                                                                                <td>{salavge.model}</td>
                                                                                <td>{
                                                                                    salavge.bids ? <ol>
                                                                                        {
                                                                                            salavge.bids.map((bid) => (
                                                                                                <>
                                                                                                    <h6 style={{fontSize: "12px"}}>{bid.name} {bid.bidAmount} {new Date(bid.created_at).toLocaleDateString()}</h6>
                                                                                                    <br/>
                                                                                                    <br/>
                                                                                                </>


                                                                                            ))
                                                                                        }
                                                                                    </ol> : <p>There are no bids</p>
                                                                                }</td>
                                                                            </tr>
                                                                        ))
                                                                    }


                                                                </> : <p>There is no active auction running</p>
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    </tbody>
                                                </table>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default CompanyAuctionDetails